import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonCard,
  IonLabel,
  IonGrid,
  IonRow,
  useIonViewWillEnter,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ErrorAlert from "../../components/Common/ErrorAlert";
import { SearchBar } from "../../components/Common/InputBar";
import {
  Product,
  useGetInventoryLazyQuery,
} from "../../lib/GraphQL/generated/types";
import _ from "lodash";

import "./style.scss";

type SelectInventoryType = Pick<
  Product,
  "id" | "name" | "price0" | "price1" | "price2" | "salePrice"
>;

const InventoryPage = () => {
  const [products, setProducts] = useState<SelectInventoryType[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [filter, setFilter] = useState<string>("descripcion");
  const [page, setPage] = useState<number>(1);
  const [input, setInput] = useState<string>("");
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const handleChangeInput = (text: string) => {
    setInput(text);
  };

  const history = useHistory();

  const [getProducts, { error, refetch }] = useGetInventoryLazyQuery({
    variables: {
      limit: 20,
      input: { name: null },
      page: 1,
    },
    onCompleted: () => {
      setFirstLoad(false);
    },
  });

  const pushData = (newData: SelectInventoryType[]) => {
    setProducts([...products, ...newData]);
  };

  const loadData = async (ev: any) => {
    const newResults = await refetch({
      input: filter === "codigo" ? { id: null } : { name: "" },
      page: page,
    });
    if (newResults.data.inventory.nextPage) {
      setPage(newResults.data.inventory.nextPage);
      pushData(newResults.data.inventory.products);
      ev.target.complete();
    } else {
      setInfiniteDisabled(true);
    }
  };

  useIonViewWillEnter(async () => {
    setProducts([]);
    setInput("");
    try {
      const newResults = await getProducts({
        variables: {
          limit: 20,
          input: filter === "codigo" ? { id: null } : { name: null },
        },
      });
      if (error) {
        setErrorMessage(error.message);
        setShowAlert(true);
      } else {
        if (newResults.data) {
          if (newResults.data.inventory.nextPage) {
            setPage(newResults.data.inventory.nextPage);
          }
          pushData(newResults.data.inventory.products);
          if (!newResults.data.inventory.nextPage) {
            setInfiniteDisabled(true);
          }
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        await setErrorMessage(e.message);
      }
      setShowAlert(true);
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilterTextHandler = useCallback(
    _.debounce(async (text: string) => {
      setPage(1);
      const newResults = await refetch({
        input:
          filter === "codigo"
            ? { id: parseInt(text) }
            : { name: text === "" ? null : text.toUpperCase() },
        page: 1,
      });
      setProducts(newResults.data.inventory.products);
      if (!newResults.data.inventory.nextPage) {
        setInfiniteDisabled(true);
      } else {
        setInfiniteDisabled(false);
      }
    }, 500),
    [filter]
  );

  const filterSelectHandler = (value: string) => {
    setFilter(value);
  };

  useEffect(() => {
    debouncedFilterTextHandler(input);
  }, [debouncedFilterTextHandler, input]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Inventario</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="light">
        <div className="search-fixed">
          <SearchBar
            placeholder="Filtrar Productos"
            valueHandleChange={handleChangeInput}
            value={input}
            filtersEnabled
            filtersOptions={[
              { value: "codigo", label: "Codigo" },
              { value: "descripcion", label: "Descripcion" },
            ]}
            filterValue={filter}
            filterHandleChange={filterSelectHandler}
          />
        </div>
        {products.length > 0 ? (
          <IonList className="list">
            {products.map((product, key) => {
              return (
                <IonCard
                  key={key}
                  className="card-short"
                  onClick={() =>
                    history.push(`/inventory/detail/${product.id}`)
                  }
                >
                  <IonGrid className="grid-fonts">
                    <IonRow
                      style={{
                        fontWeight: "600",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                      class="ion-justify-content-between"
                    >
                      <IonLabel>{product.name}</IonLabel>
                      <IonLabel>{product.id}</IonLabel>
                    </IonRow>
                  </IonGrid>
                </IonCard>
              );
            })}
          </IonList>
        ) : (
          <h1>No hay resultados</h1>
        )}
        <IonInfiniteScroll
          onIonInfinite={loadData}
          threshold="50px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
      <IonLoading isOpen={firstLoad} message={"Cargando..."} />
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
    </IonPage>
  );
};

export default InventoryPage;
