import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonCard,
  IonToolbar,
  useIonViewWillEnter,
  IonBackButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
} from "@ionic/react";
import { useState } from "react";
import { useParams } from "react-router";
import ErrorAlert from "../../components/Common/ErrorAlert";
import {
  GetProductDetailQuery,
  useGetProductDetailLazyQuery,
} from "../../lib/GraphQL/generated/types";
import { formatToCurrency } from "../../utils/formatToCurrency";

import "./style.scss";
import { formatWithCommas } from "../../utils/calculator";

export type SelectProducType = GetProductDetailQuery["product"];

type ProductParams = { productId: string };

const ProductDetail = () => {
  let { productId } = useParams<ProductParams>();
  const [productInfo, setProductInfo] = useState<
    SelectProducType | null | undefined
  >();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [getProduct, { error, loading }] = useGetProductDetailLazyQuery({
    variables: { id: parseInt(productId) },
  });

  const getHighestPrice = () => {
    if (!productInfo) return 0;
    const salePrice = productInfo.salePrice ? productInfo.salePrice : 0;
    const prices = [
      productInfo.price0,
      productInfo.price1,
      productInfo.price2,
      salePrice,
    ];
    return `${formatToCurrency(Math.max(...prices))}`;
  };

  useIonViewWillEnter(async () => {
    try {
      const results = await getProduct();
      if (error) {
        setErrorMessage(error.message);
        setShowAlert(true);
      } else {
        if (results.data) {
          setProductInfo(results.data.product);
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        await setErrorMessage(e.message);
      }
      setShowAlert(true);
    }
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Producto #{productInfo?.id}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="light">
        <IonCard>
          <IonGrid className="detail-grid">
            <IonRow className="first-row">
              <IonCol className="col-left">Codigo: {productId}</IonCol>
              <IonCol className="col-right">
                Producto: {productInfo?.name}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="col-left">Precio: {getHighestPrice()}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="col-left">
                ITBIS:{" "}
                {productInfo?.tax
                  ? `${formatWithCommas(productInfo.tax)}%`
                  : "0"}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
      </IonContent>
      <IonLoading isOpen={loading} message={"Cargando..."} />
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
    </IonPage>
  );
};

export default ProductDetail;
