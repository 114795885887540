import React from "react";
import {
  CustomerContext,
  CustomerContextType,
} from "../context/CustomerContext";

const useCustomer = (): CustomerContextType => {
  const context = React.useContext(CustomerContext);

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
};

export default useCustomer;
