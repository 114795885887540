import { IonAlert } from "@ionic/react";

type ErrorAlertProps = {
  isOpen: boolean;
  dismiss: () => void;
  message: string;
};

const ErrorAlert = ({ isOpen, dismiss, message }: ErrorAlertProps) => (
  <IonAlert
    isOpen={isOpen}
    onDidDismiss={dismiss}
    cssClass="my-custom-class"
    header={"Error"}
    subHeader={"Description"}
    message={message}
    buttons={["OK"]}
  />
);

export default ErrorAlert;
