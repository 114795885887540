import React from "react";

import { UserContext, UserContextType } from "../context/UserContext";

const useUser = (): UserContextType => {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
};

export default useUser;
