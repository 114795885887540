export const AUTH_ROUTE = "/auth";
export const CUSTOMER_ROUTE = "/clients";
export const PRODUCT_ROUTE = "/inventory";
export const QUOTE_ROUTE = "/quotes";
export const RECEIPT_ROUTE = "/receipts";

export const CUSTOMER_SUB_ROUTES = {
  CLIENT_LISTING: `${CUSTOMER_ROUTE}/`,
  CLIENT_DETAIL: `${CUSTOMER_ROUTE}/detail/:customerId`,
};
export const PRODUCT_SUB_ROUTES = {
  PRODUCT_LISTING: `${PRODUCT_ROUTE}/`,
  PRODUCT_DETAIL: `${PRODUCT_ROUTE}/detail/:productId`,
};

export const QUOTES_SUB_ROUTES = {
  QUOTE_LISTING: `${QUOTE_ROUTE}/`,
  QUOTE_DETAIL: `${QUOTE_ROUTE}/detail/:quoteId`,
  QUOTE_CREATION: `${QUOTE_ROUTE}/new`,
  QUOTE_EDIT: `${QUOTE_ROUTE}/edit/:quoteId`,
  DETAIL_EDIT: `${QUOTE_ROUTE}/edit/:quoteId/:itemNumber`,
};

export const RECEIPT_SUB_ROUTES = {
  RECEIPT_CREATION: `${RECEIPT_ROUTE}/create`,
  RECEIPT_DETAIL: `${RECEIPT_ROUTE}/detail/:receiptId`,
  RECEIPT_LISTING: `${RECEIPT_ROUTE}/`,
};

export const routes = {
  HOME: "/",
  LOGIN: `${AUTH_ROUTE}/login`,
  FORGOT_PASSWORD: `${AUTH_ROUTE}/forgot-password`,
  RESET_PASSWORD: `${AUTH_ROUTE}/reset-password`,
  ...CUSTOMER_SUB_ROUTES,
  ...PRODUCT_SUB_ROUTES,
  ...QUOTES_SUB_ROUTES,
  ...RECEIPT_SUB_ROUTES,
};
