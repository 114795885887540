import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {
  archiveOutline,
  archiveSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  homeOutline,
  homeSharp,
  cashOutline,
} from "ionicons/icons";
import "./style.css";
import useUser from "../../../hooks/useUser";
import {
  CUSTOMER_ROUTE,
  PRODUCT_ROUTE,
  QUOTE_ROUTE,
} from "../../../constants/routes";
import useCompany from "../../../hooks/useCompany";
import useCustomer from "../../../hooks/useCustomer";
import { getVersion } from "../../../utils/version";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: "Home",
    url: "/",
    iosIcon: homeOutline,
    mdIcon: homeSharp,
  },
  {
    title: "Clientes",
    url: CUSTOMER_ROUTE,
    iosIcon: paperPlaneOutline,
    mdIcon: paperPlaneSharp,
  },
  {
    title: "Pedidos",
    url: QUOTE_ROUTE,
    iosIcon: cashOutline,
    mdIcon: cashOutline,
  },
  {
    title: "Productos",
    url: PRODUCT_ROUTE,
    iosIcon: archiveOutline,
    mdIcon: archiveSharp,
  },
];

const MenuOptions: React.FC = () => {
  const location = useLocation();
  const { user } = useUser();
  const { company } = useCompany();
  const { customer } = useCustomer();
  const { handleSignOut: handleUserSignOut } = useUser();
  const handleSignOut = async () => {
    handleUserSignOut();
  };

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>
            {`${user?.firstName} ${user?.lastName}`}{" "}
          </IonListHeader>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <IonNote>{company?.name}</IonNote>
            <IonNote style={{ marginTop: "-28px" }}>
              Versión: {getVersion()}
            </IonNote>
            {customer?.name && <IonNote>Cliente: {customer?.name}</IonNote>}
          </div>

          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
        <IonButton onClick={handleSignOut}>Logout</IonButton>
      </IonContent>
    </IonMenu>
  );
};

export default MenuOptions;
