export const formatPhoneNumber = (phoneNumber: string | null | undefined) => {
  if (!phoneNumber) {
    return "";
  }
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{1,3})?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const countryCode = match[1] ? "+" + match[1] : "";
    return countryCode + " (" + match[2] + ") " + match[3] + "-" + match[4];
  }
  return null;
};
