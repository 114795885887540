import {
  InputChangeEventDetail,
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonModal,
  IonTextarea,
  TextareaChangeEventDetail,
} from "@ionic/react";

type MailFormType = {
  isOpen: boolean;
  subject: string;
  address: string;
  body: string;
  handleCloseModal: () => void;
  handleSubjectChange: (e: CustomEvent<InputChangeEventDetail>) => void;
  handleAddressChange: (e: CustomEvent<InputChangeEventDetail>) => void;
  handleBodyChange: (e: CustomEvent<TextareaChangeEventDetail>) => void;
  sendFunction: () => void;
};

export const MailFormModal = ({
  isOpen,
  subject,
  address,
  body,
  handleSubjectChange,
  handleAddressChange,
  handleBodyChange,
  handleCloseModal,
  sendFunction,
}: MailFormType) => {
  return (
    <IonModal isOpen={isOpen}>
      <IonContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendFunction();
          }}
        >
          <IonItem>
            <IonInput
              value={address}
              type="email"
              placeholder="Email"
              onIonChange={handleAddressChange}
              clearInput
              required
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonInput
              value={subject}
              placeholder="Asunto"
              onIonChange={handleSubjectChange}
              clearInput
              required
            ></IonInput>
          </IonItem>

          <IonItem>
            <IonTextarea
              placeholder="Cuerpo del correo..."
              value={body}
              onIonChange={handleBodyChange}
            ></IonTextarea>
          </IonItem>
          <div className="buttons-center">
            <IonButton type="button" onClick={handleCloseModal}>
              Go back
            </IonButton>
            <IonButton type="submit">Submit</IonButton>
          </div>
        </form>
      </IonContent>
    </IonModal>
  );
};
