import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonCard,
  useIonLoading,
  IonToolbar,
  useIonViewWillEnter,
  IonBackButton,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonAlert,
  InputChangeEventDetail,
  TextareaChangeEventDetail,
  useIonAlert,
  IonLoading,
  // IonFab,
  // IonIcon,
  // IonButton,
} from "@ionic/react";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { DetailButtons } from "../../components/Common/DetailButtons";
import ErrorAlert from "../../components/Common/ErrorAlert";
import {
  GetQuoteDetailQuery,
  SaleStatus,
  SaleType,
  useGetQuoteDetailLazyQuery,
} from "../../lib/GraphQL/generated/types";
import { computeQuoteSummary, getITBIS } from "../../utils/calculator";
import { sqlToDisplay } from "../../utils/date";

import "./style.scss";
import { downloadFilePdf, sendMail } from "../../utils/fileManagement";
import { MailFormModal } from "../../components/Common/MailForm";
// import { arrowForwardCircle } from "ionicons/icons";
import useCustomer from "../../hooks/useCustomer";
import { statusColors } from ".";
import { formatToCurrency } from "../../utils/formatToCurrency";

type QuoteParams = { quoteId: string };

const QuoteDetail = () => {
  const history = useHistory();
  const customerContext = useCustomer();
  let { quoteId } = useParams<QuoteParams>();
  const [quoteInfo, setQuoteInfo] = useState<GetQuoteDetailQuery["quote"]>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showBlob, setShowBlob] = useState<boolean>(false);
  const [blob] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openMail, setOpenMail] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [asunto, setAsunto] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [present, dismiss] = useIonLoading();
  const [openAlert] = useIonAlert();

  const [getQuote, { error, loading }] = useGetQuoteDetailLazyQuery({
    variables: { id: parseInt(quoteId) },
    fetchPolicy: "no-cache",
  });

  useIonViewWillEnter(async () => {
    try {
      const results = await getQuote();
      if (error) {
        setErrorMessage(error.message);
        setShowAlert(true);
      } else {
        if (results.data) {
          setQuoteInfo(results.data.quote);
          setEmail(results.data.quote?.customer?.email || "");
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        await setErrorMessage(e.message);
      }
      setShowAlert(true);
    }
  });

  const handleAddressChange = (event: CustomEvent<InputChangeEventDetail>) => {
    setEmail(event.detail.value!);
  };
  const handleSubjectChange = (event: CustomEvent<InputChangeEventDetail>) => {
    setAsunto(event.detail.value!);
  };
  const handleBodyChange = (event: CustomEvent<TextareaChangeEventDetail>) => {
    setMessage(event.detail.value!);
  };

  const sendMailFunction = async () => {
    present({
      message: "Enviando...",
    });
    try {
      await sendMail({
        uri: `quote/${quoteId}`,
        subject: asunto,
        message: message,
        to: email,
      });
      openAlert({
        header: "Success",
        message: "Your mail has been sent",
        buttons: ["OK"],
      });
    } catch (e) {
      openAlert({
        cssClass: "my-css",
        header: "Alert",
        message: String(e as Error),
        buttons: [
          "Cancel",
          { text: "Ok", handler: (d) => console.log("ok pressed") },
        ],
        onDidDismiss: (e) => console.log("did dismiss"),
      });
    }
    dismiss();
  };

  const middleFunc = async () => {
    present({
      message: "Descargando...",
    });
    try {
      await downloadFilePdf({
        fileName: `quote-${quoteId}.pdf`,
        uri: `quote/${quoteId}`,
      });
    } catch (e) {
      openAlert({
        cssClass: "my-css",
        header: "Alert",
        message: String(e as Error),
        buttons: [
          "Cancel",
          { text: "Ok", handler: (d) => console.log("ok pressed") },
        ],
        onDidDismiss: (e) => console.log("did dismiss"),
      });
    }
    dismiss();
  };

  const { details } = computeQuoteSummary(quoteInfo?.details!);

  const handleMailClose = () => {
    setEmail("");
    setAsunto("");
    setMessage("");
    setOpenMail(false);
  };

  const handleMailOpen = () => {
    setOpenMail(true);
  };

  // const handleNewQuote = () => {
  //   if (quoteInfo?.customer?.id) {
  //     customerContext.setCustomer({
  //       id: quoteInfo?.customer?.id,
  //       name: quoteInfo?.customer?.name,
  //       customerTypeId: quoteInfo?.customer?.customerTypeId,
  //       paymentTerms: quoteInfo?.customer?.paymentTerms,
  //     });
  //   }
  //   history.replace("/quotes/new");
  // };

  const handleEditQuote = () => {
    if (quoteInfo?.customer?.id) {
      customerContext.setCustomer({
        id: quoteInfo?.customer?.id,
        name: quoteInfo?.customer?.name,
        customerTypeId: quoteInfo?.customer?.customerTypeId,
        paymentTerms: quoteInfo?.customer?.paymentTerms,
      });
    }
    history.push(`/quotes/edit/${quoteId}`);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="quotes" />
          </IonButtons>
          <IonTitle>Pedido #{quoteInfo?.id}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonCard className="fullcard">
          <DetailButtons
            editFunction={handleEditQuote}
            middle="Descargar"
            middleFunction={middleFunc}
            mailFunction={handleMailOpen}
            omit={
              quoteInfo?.status !== SaleStatus.Billing
                ? { edit: true }
                : undefined
            }
          />
          <IonGrid className="detail-grid">
            <IonRow className="first-row">
              <IonCol className="col-left">Codigo: #{quoteId}</IonCol>
              <IonCol className="col-right">
                {sqlToDisplay(quoteInfo?.createdAt || new Date())}
              </IonCol>
            </IonRow>
            {quoteInfo?.status ? (
              <IonRow>
                <IonCol className="col-left">
                  Estado:
                  <div
                    style={{
                      color: statusColors[quoteInfo?.status!].color,
                      paddingLeft: "5px",
                    }}
                  >
                    {statusColors[quoteInfo?.status!].label}
                  </div>
                </IonCol>
              </IonRow>
            ) : null}
            <IonRow>
              <IonCol className="col-left">
                Cliente: {quoteInfo?.customer?.name}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="col-left">
                Vendedor: {quoteInfo?.sellerId}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="col-left">
                Tipo Venta:{" "}
                {quoteInfo?.saleType === SaleType.Co
                  ? "Contado"
                  : `Credito ${quoteInfo?.paymentTerms} días`}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="col-left">
                Observaciones: {quoteInfo?.observations}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="col-left">Desglose de Productos:</IonCol>
            </IonRow>
            <IonRow className="list-row">
              <IonList className="list list-detail">
                {details
                  ? details.map((product, index) => {
                      return (
                        <IonCard key={index} className="card-short">
                          <IonGrid className="grid-fonts">
                            <IonRow
                              style={{
                                fontWeight: "600",
                                marginBottom: "5px",
                                marginTop: "5px",
                              }}
                              class="ion-justify-content-between"
                            >
                              <IonLabel>
                                #{product.order} - {product.product?.name}
                              </IonLabel>
                              <IonLabel style={{ fontSize: "3vmin" }}>
                                Codigo: #{product.productId}
                              </IonLabel>
                            </IonRow>
                            <IonRow class="ion-justify-content-between">
                              <IonLabel>{`Cantidad: ${product.quantity}`}</IonLabel>
                              <IonLabel>
                                Precio: {formatToCurrency(product.price!)}
                              </IonLabel>
                              <IonLabel>
                                Subtotal:{" "}
                                {formatToCurrency(
                                  product.price! * product.quantity!
                                )}
                              </IonLabel>
                            </IonRow>
                            <IonRow class="ion-justify-content-between">
                              <IonLabel>
                                ITBIS: {getITBIS(product.tax)}
                              </IonLabel>
                              <IonLabel
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                Total: {formatToCurrency(product.total)}
                              </IonLabel>
                            </IonRow>
                          </IonGrid>
                        </IonCard>
                      );
                    })
                  : null}
              </IonList>
            </IonRow>
          </IonGrid>
        </IonCard>
        {/* <IonFab
          vertical="bottom"
          horizontal="end"
          style={{ paddingBottom: "4rem" }}
        >
          <IonButton onClick={handleNewQuote}>
            Crear Pedido <IonIcon icon={arrowForwardCircle} />
          </IonButton>
        </IonFab> */}
        <div className="price-fixed">
          <IonCard className="price-card">
            <IonGrid>
              <IonRow className="row-price">
                <IonCol className="col-center">
                  <div className="col-price ">
                    <IonLabel>Subtotal</IonLabel>
                    <IonTitle>
                      {formatToCurrency(quoteInfo?.gross || 0)}
                    </IonTitle>
                  </div>
                </IonCol>
                <IonCol className="col-center">
                  <div className="col-price ">
                    <IonLabel>Total</IonLabel>
                    <IonTitle>{formatToCurrency(quoteInfo?.net || 0)}</IonTitle>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCard>
        </div>
      </IonContent>
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
      <IonLoading isOpen={loading} message={"Cargando..."} />
      <IonAlert
        isOpen={showBlob}
        onDidDismiss={() => setShowBlob(false)}
        message={blob}
      />
      <MailFormModal
        isOpen={openMail}
        address={email}
        handleAddressChange={handleAddressChange}
        body={message}
        handleBodyChange={handleBodyChange}
        subject={asunto}
        handleSubjectChange={handleSubjectChange}
        sendFunction={sendMailFunction}
        handleCloseModal={handleMailClose}
      />
    </IonPage>
  );
};

export default QuoteDetail;
