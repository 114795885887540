type VerticalDividerProps = {
  margin?: string;
  weight?: string;
  color?: string;
};

export const VerticalDivider = ({
  margin,
  weight,
  color,
}: VerticalDividerProps) => {
  return (
    <div
      className="vl"
      style={{
        borderLeft: `${weight ? weight : "1px"} solid ${
          color ? color : "black"
        }  `,
        marginTop: `${margin ? margin : 0}`,
        marginBottom: `${margin ? margin : 0}`,
        height: "auto",
      }}
    ></div>
  );
};
