import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonCard,
  IonLabel,
  IonGrid,
  IonRow,
  useIonViewWillEnter,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonText,
  IonCol,
  IonTextarea,
  IonLoading,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import { search, closeCircleSharp } from "ionicons/icons";
import ErrorAlert from "../../../components/Common/ErrorAlert";
import { SearchBar } from "../../../components/Common/InputBar";
import {
  CreateQuoteDetailInput,
  Product,
  QuoteDetailInput,
  SalePrices,
  useGetInventoryLazyQuery,
  useGetProductDetailLazyQuery,
} from "../../../lib/GraphQL/generated/types";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Virtual } from "swiper";

import "../style.scss";

import { SelectProducType } from "../../Inventory/detail";
import {
  calcBruto,
  calcDescuento,
  calcItbis,
  calcNeto,
  roundNumber,
} from "../../../utils/calculator";
import useCompany from "../../../hooks/useCompany";
import _ from "lodash";
import { getPrecioVenta } from "../../../utils/getSalePrice";
import { formatToCurrency } from "../../../utils/formatToCurrency";

type SelectInventoryType = Pick<
  Product,
  "id" | "name" | "price0" | "price1" | "price2" | "salePrice"
> & { selected?: boolean };

type DetailProductType = {
  priceType: SalePrices;
  product: SelectInventoryType;
  handleAdd: (
    cantidad: number,
    product: SelectInventoryType,
    total: number,
    tax: number
  ) => void;
  detail: CreateQuoteDetailInput[] | QuoteDetailInput[];
};

type SearchProductType = {
  setSelectedProduct: (product: SelectInventoryType) => void;
};

type AddProductType = {
  priceType: SalePrices;
  detail: CreateQuoteDetailInput[] | QuoteDetailInput[];
  handleAdd: (
    cantidad: number,
    product: SelectInventoryType,
    tax: number,
    total: number
  ) => void;
  handleClose: () => void;
};

export const SlideSearch = ({ setSelectedProduct }: SearchProductType) => {
  const [products, setProducts] = useState<SelectInventoryType[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [start, setStart] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("descripcion");
  const [page, setPage] = useState<number>(1);
  const [input, setInput] = useState<string>("");
  const [firstLoading, setFirstLoading] = useState<boolean>(true);

  const handleChangeInput = (text: string) => {
    setInput(text);
  };

  const [getProducts, { error, refetch }] = useGetInventoryLazyQuery({
    variables: { input: {}, limit: 20 },
    onCompleted: () => {
      setFirstLoading(false);
    },
  });
  const swiper = useSwiper();
  const pushData = (newData: SelectInventoryType[]) => {
    setProducts([...products, ...newData]);
  };

  const loadData = async (ev: any) => {
    const newResults = await refetch({
      input: { id: null },
      page: page,
    });
    if (newResults.data.inventory.nextPage) {
      setPage(newResults.data.inventory.nextPage);
      pushData(newResults.data.inventory.products);
      ev.target.complete();
    } else {
      setInfiniteDisabled(true);
    }
  };

  useIonViewWillEnter(async () => {
    setStart(true);
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilterTextHandler = useCallback(
    _.debounce(async (text: string) => {
      setPage(1);
      const newResults = await refetch({
        input:
          filter === "codigo"
            ? { id: parseInt(text) }
            : { name: text === "" ? null : text.toUpperCase() },
        page: 1,
      });
      setProducts(newResults.data.inventory.products);
      if (!newResults.data.inventory.nextPage) {
        setInfiniteDisabled(true);
      } else {
        setInfiniteDisabled(false);
      }
    }, 500),
    [filter]
  );

  const filterSelectHandler = (value: string) => {
    setFilter(value);
  };

  const handleSelectProduct = (product: SelectInventoryType) => {
    setSelectedProduct(product);
    swiper.slideNext();
  };

  useEffect(() => {
    const start = async () => {
      try {
        const newResults = await getProducts({ variables: { limit: 20 } });
        if (error) {
          setErrorMessage(error.message);
          setShowAlert(true);
        } else {
          if (newResults.data) {
            if (newResults.data.inventory.nextPage) {
              setPage(newResults.data.inventory.nextPage);
            }
            pushData(newResults.data.inventory.products);
            if (!newResults.data.inventory.nextPage) {
              setInfiniteDisabled(true);
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          await setErrorMessage(e.message);
        }
        setShowAlert(true);
      }
    };
    setStart(false);
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  useEffect(() => {
    debouncedFilterTextHandler(input);
  }, [debouncedFilterTextHandler, input]);

  return (
    <IonContent fullscreen color="light">
      <SearchBar
        placeholder="Filtrar Productos"
        valueHandleChange={handleChangeInput}
        value={input}
        filtersEnabled
        filtersOptions={[
          { value: "codigo", label: "Codigo" },
          { value: "descripcion", label: "Descripcion" },
        ]}
        filterValue={filter}
        filterHandleChange={(value) => filterSelectHandler(value)}
      />
      {products.length > 0 ? (
        <IonList className="list">
          {products.map((product, key) => {
            return (
              <IonCard
                key={key}
                className="card"
                onClick={() => handleSelectProduct(product)}
              >
                <IonGrid className="grid-fonts">
                  <IonRow
                    style={{
                      fontWeight: "600",
                      marginBottom: "5px",
                      marginTop: "5px",
                    }}
                    class="ion-justify-content-between"
                  >
                    <IonLabel>{product.name}</IonLabel>
                    <IonLabel>{product.id}</IonLabel>
                  </IonRow>
                </IonGrid>
              </IonCard>
            );
          })}
        </IonList>
      ) : (
        <h1>No hay resultados</h1>
      )}
      <IonInfiniteScroll
        onIonInfinite={loadData}
        threshold="100px"
        disabled={isInfiniteDisabled}
      >
        <IonInfiniteScrollContent
          loadingSpinner="bubbles"
          loadingText="Loading more data..."
        ></IonInfiniteScrollContent>
      </IonInfiniteScroll>
      <IonLoading isOpen={firstLoading} message={"Cargando..."} />
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
    </IonContent>
  );
};

const SlideAddProducts = ({
  priceType,
  product,
  handleAdd,
}: DetailProductType) => {
  const [precioVenta, setPrecioVenta] = useState<number>(0);
  const [cantidad, setCantidad] = useState<number>(1);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [observations, setObservations] = useState<string>("");
  const [productInfo, setProductInfo] = useState<SelectProducType>();
  const [precioElegido, setPrecioElegido] = useState<number>(precioVenta);
  const [makeZero, setMakeZero] = useState<boolean>(
    precioVenta === 0 ? true : false
  );

  const [, { error, loading: productLoading, refetch }] =
    useGetProductDetailLazyQuery({
      variables: { id: product.id },
    });

  const swiper = useSwiper();

  const reset = () => {
    setCantidad(1);
    swiper.slidePrev();
  };

  const loadData = async () => {
    try {
      const results = await refetch({
        id: product.id,
      });
      if (error) {
        setErrorMessage(error.message);
        setShowAlert(true);
      } else {
        if (results.data) {
          setProductInfo(results.data.product);
          setPrecioVenta(getPrecioVenta(results.data.product, priceType)!);
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        await setErrorMessage(e.message);
      }
      setShowAlert(true);
    }
  };

  const { company } = useCompany();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.id]);

  useEffect(() => {
    setPrecioElegido(precioVenta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [precioVenta]);

  let subTotal = productInfo ? calcBruto(precioElegido, cantidad) : 0;
  let descuento = calcDescuento(subTotal, 0);
  let itbis = productInfo?.tax
    ? roundNumber(calcItbis(company?.config?.tax!, precioElegido, cantidad))
    : 0;
  let neto = calcNeto(subTotal, descuento, itbis);

  const toggleZero = () => {
    if (makeZero) {
      setPrecioElegido(0);
    } else {
      setPrecioElegido(precioVenta);
    }
    setMakeZero(!makeZero);
  };

  return (
    <IonContent fullscreen>
      <IonGrid>
        <IonRow>
          <IonCol class="ion-text-start ion-align-self-center" size="8">
            <IonText>
              <h3>{product.name}</h3>
            </IonText>
          </IonCol>
          <IonCol class="ion-text-end ion-align-self-center">
            <IonText>
              <h2>{`#${product.id}`}</h2>
            </IonText>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonItem>
        <IonLabel>Cantidad</IonLabel>
        <IonInput
          type="number"
          value={cantidad}
          min={1}
          defaultValue={1}
          placeholder="Enter Number"
          onIonChange={(e) => setCantidad(parseInt(e.detail.value!))}
        />
      </IonItem>
      <IonItem>
        <div style={{ width: "70%" }}>
          <IonLabel>Precio</IonLabel>
        </div>
        <IonButton onClick={toggleZero}>
          <IonIcon icon={makeZero ? closeCircleSharp : search} />
        </IonButton>
        <div style={{ marginLeft: "25px" }}>
          <IonText>{formatToCurrency(precioElegido)}</IonText>
        </div>
      </IonItem>
      <IonItem>
        <IonLabel position="floating">Observaciones</IonLabel>
        <IonTextarea
          value={observations}
          maxlength={50}
          onIonChange={(e) => setObservations(e.detail.value!)}
        />
      </IonItem>

      {productInfo ? (
        <>
          <IonItem>
            <IonLabel>Subtotal</IonLabel>
            <IonLabel>{formatToCurrency(subTotal)}</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Impuestos</IonLabel>
            <IonLabel>{formatToCurrency(itbis)}</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>Total</IonLabel>
            <IonLabel>{formatToCurrency(neto)}</IonLabel>
          </IonItem>
          <IonGrid>
            <IonRow className="ion-justify-content-evenly">
              <IonButton color="light" onClick={reset}>
                Atrás
              </IonButton>
              <IonButton
                onClick={() => {
                  handleAdd(cantidad, product, itbis, precioElegido);
                  reset();
                }}
              >
                Agregar
              </IonButton>
            </IonRow>
          </IonGrid>
        </>
      ) : null}
      <IonLoading isOpen={productLoading} message="Cargando..." />
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
    </IonContent>
  );
};

const SelectProducts = ({
  priceType,
  detail,
  handleClose,
  handleAdd,
}: AddProductType) => {
  const [tempProduct, setTempProduct] = useState<SelectInventoryType>({
    id: 0,
    name: "producto nulo",
    price0: 0,
    price1: 0,
    price2: 0,
    salePrice: 0,
  });

  const handleChangeTempProduct = (product: SelectInventoryType) => {
    setTempProduct(product);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handleClose}>
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Selecciona Productos</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Swiper
          modules={[Virtual]}
          allowTouchMove={false}
          spaceBetween={10}
          virtual
          style={{ height: "100%" }}
        >
          <SwiperSlide>
            <SlideSearch setSelectedProduct={handleChangeTempProduct} />
          </SwiperSlide>
          <SwiperSlide>
            <SlideAddProducts
              priceType={priceType}
              product={tempProduct}
              handleAdd={handleAdd}
              detail={detail}
            />
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonPage>
  );
};

export default SelectProducts;
