import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonNote,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { search, trash } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ErrorAlert from "../../../components/Common/ErrorAlert";
import {
  CreateQuoteInput,
  InputMaybe,
  QuoteDetail,
  SalePrices,
  SaleType,
  useCreateQuoteMutation,
  useGetCustomerTypesQuery,
} from "../../../lib/GraphQL/generated/types";
import { computeQuoteSummary } from "../../../utils/calculator";
import ClientSearch from "../../Customer/search";
import SelectProducts from "./selectCreation";

import "../style.scss";
import useCustomer from "../../../hooks/useCustomer";
import { getPrecioVenta } from "../../../utils/getSalePrice";
import { formatToCurrency } from "../../../utils/formatToCurrency";

const CreateQuote = () => {
  const localCustomer = useCustomer();
  const [client, setClient] = useState<number>(localCustomer.customer?.id || 0);
  const [tipoVenta, setTipoVenta] = useState<"Co" | "Cr">("Cr");
  const [observations, setObservations] = useState<string>("");
  const [detalle, setDetalle] = useState<QuoteDetail[]>([]);
  const [openSearchClient, setOpenSearchClient] = useState<boolean>(false);
  const [openAddProduct, setOpenAddProduct] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const handleDetailArray = (arreglo: QuoteDetail[]) => {
    setDetalle(arreglo);
  };

  const history = useHistory();

  const reset = () => {
    setClient(0);
    setTipoVenta("Cr");
    setObservations("");
    setDetalle([]);
  };

  const [createQuote, { error, loading }] = useCreateQuoteMutation();

  const { data: customerTypesData, loading: customerTypesLoading } =
    useGetCustomerTypesQuery({
      fetchPolicy: "cache-first",
    });

  const saveQuote = async () => {
    const quote: CreateQuoteInput = {
      customerId: client,
      paymentTerms: localCustomer.customer?.paymentTerms!,
      saleType: tipoVenta.toUpperCase() as InputMaybe<SaleType>,
      observations: observations,
      details: detalle.map((product) => ({
        productId: product.productId,
        quantity: product.quantity,
        price: product.price,
        discountPercentage: product.discountPercentage,
        taxPercentage: product.taxPercentage,
        observations: product.observations,
      })),
    };
    try {
      const quoteCreated = await createQuote({ variables: { input: quote } });
      if (error) {
        setErrorMessage(error.message);
        setShowAlert(true);
      } else {
        history.replace(`detail/${quoteCreated.data?.createQuote?.id}`);
      }
    } catch (e) {
      if (e instanceof Error) {
        await setErrorMessage(e.message);
      }
      setShowAlert(true);
    }
  };

  const { bruto, neto, itbis, details } = computeQuoteSummary(detalle);

  const DeleteDetail = (order: number) => {
    const nuevoDetalle = detalle.filter((_value, index) => index !== order);
    handleDetailArray(nuevoDetalle);
  };

  const getPriceCategory = (customerTypeId: number) => {
    if (customerTypesData && customerTypeId) {
      const customerType = customerTypesData.getCustomerTypes.find(
        (customerType) => customerType.id === customerTypeId
      );
      return customerType?.salePrice;
    }
    return SalePrices.SalePrice;
  };

  useEffect(() => {
    if (!customerTypesLoading)
      setDetalle((prev) =>
        prev.map((detalle) => {
          const currentProduct = detalle.product!;
          const currentCustomerType = customerTypesData!.getCustomerTypes.find(
            (type) => type.id === localCustomer.customer?.customerTypeId
          );

          const currentCustomersProductPrice = getPrecioVenta(
            currentProduct,
            currentCustomerType!.salePrice!
          );

          return {
            ...detalle,
            price:
              currentCustomersProductPrice || currentProduct.salePrice! || 0,
          };
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Nuevo Pedido</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonCard className="edit-card">
          <IonItem>
            <IonLabel>Cliente</IonLabel>
            <IonInput
              value={client}
              placeholder="Escribe el cliente"
              onIonChange={(e) => setClient(parseInt(e.detail.value!))}
            />
            <IonNote style={{ alignSelf: "center", paddingRight: "1rem" }}>
              {localCustomer.customer?.name}
            </IonNote>
            <IonButton onClick={() => setOpenSearchClient(true)}>
              <IonIcon icon={search} />
            </IonButton>
          </IonItem>
          <IonItem>
            <IonLabel>Tipo de Venta</IonLabel>
            <IonSelect
              value={tipoVenta}
              placeholder="Select One"
              onIonChange={(e) => setTipoVenta(e.detail.value)}
            >
              <IonSelectOption value="Co">CONTADO</IonSelectOption>
              <IonSelectOption value="Cr">CREDITO</IonSelectOption>
            </IonSelect>
          </IonItem>
          {tipoVenta === "Cr" ? (
            <IonItem>
              <IonLabel>Terminos de Pago</IonLabel>
              <IonText style={{ paddingRight: "1.2rem" }}>
                {localCustomer.customer?.paymentTerms || 0} DIAS
              </IonText>
            </IonItem>
          ) : null}
          <IonItem>
            <IonLabel position="floating">Observaciones</IonLabel>
            <IonTextarea
              value={observations.toUpperCase()}
              maxlength={50}
              onIonChange={(e) => setObservations(e.detail.value!)}
            />
          </IonItem>
          <IonItem>
            <IonLabel>Desglose de productos</IonLabel>
            {client ? (
              <IonButton onClick={() => setOpenAddProduct(true)}>
                <IonIcon icon={search} />
              </IonButton>
            ) : null}
          </IonItem>
          <IonList
            className="list list-edit"
            style={{ height: "calc(100% - 300px)", overflow: "scroll" }}
          >
            {details?.map((fila, index) => {
              return (
                <IonCard key={index} className="select-card">
                  <IonGrid className="grid-fonts edit-grid">
                    <div className="edit-row-div">
                      <IonRow
                        style={{
                          fontWeight: "600",
                          marginBottom: "5px",
                          marginTop: "5px",
                        }}
                        class="ion-justify-content-between"
                      >
                        <IonLabel>
                          #{index + 1} {fila.product?.name}
                        </IonLabel>
                        <IonLabel>Codigo: #{fila.productId} </IonLabel>
                      </IonRow>
                      <IonRow class="ion-justify-content-between">
                        <IonLabel>{`Cantidad: ${fila.quantity}`}</IonLabel>
                        <IonLabel>{`Total: ${formatToCurrency(
                          fila.subTotal
                        )}`}</IonLabel>
                      </IonRow>
                    </div>
                    <div className="delete-div">
                      <IonButton onClick={() => DeleteDetail(index)}>
                        <IonIcon icon={trash} />
                      </IonButton>
                    </div>
                  </IonGrid>
                </IonCard>
              );
            })}
          </IonList>
        </IonCard>
        <div className="price-fixed">
          <IonCard className="price-card">
            <IonGrid>
              <IonRow className="ion-justify-content-evenly">
                <IonButton onClick={reset} color="light">
                  Limpiar
                </IonButton>
                <IonButton onClick={saveQuote}>Grabar</IonButton>
              </IonRow>
            </IonGrid>
            <IonGrid>
              <IonRow className="row-price">
                <IonCol className="col-center">
                  <div className="col-price ">
                    <IonLabel>Subtotal</IonLabel>
                    <IonTitle>{formatToCurrency(neto)}</IonTitle>
                  </div>
                </IonCol>
                <IonCol className="col-center">
                  <div className="col-price ">
                    <IonLabel>Itbis</IonLabel>
                    <IonTitle>{formatToCurrency(itbis)}</IonTitle>
                  </div>
                </IonCol>
                <IonCol className="col-center">
                  <div className="col-price ">
                    <IonLabel>Total</IonLabel>
                    <IonTitle>{formatToCurrency(bruto)}</IonTitle>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCard>
        </div>
        <IonLoading
          isOpen={loading || customerTypesLoading}
          message={"Cargando..."}
        />
        <ErrorAlert
          isOpen={showAlert}
          dismiss={() => setShowAlert(false)}
          message={errorMessage}
        />
      </IonContent>
      <IonModal isOpen={openSearchClient}>
        <ClientSearch
          handleClientSelect={setClient}
          handleClose={() => {
            setOpenSearchClient(false);
          }}
        />
      </IonModal>
      <IonModal isOpen={openAddProduct}>
        <SelectProducts
          priceType={
            getPriceCategory(localCustomer.customer?.customerTypeId!) ||
            SalePrices.SalePrice
          }
          detail={detalle}
          handleAdd={async (cantidad, product, tax, precio) => {
            const nuevoProducto = {
              quantity: cantidad,
              discountPercentage: 0,
              price: precio,
              taxPercentage: tax,
              productId: product.id,
              product: product,
            };
            const nuevoDetalle = [...detalle, nuevoProducto] as QuoteDetail[];

            if (nuevoDetalle) {
              handleDetailArray(nuevoDetalle);
            }
          }}
          handleClose={() => setOpenAddProduct(false)}
        />
      </IonModal>
    </IonPage>
  );
};

export default CreateQuote;
