import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonCard,
  IonToolbar,
  useIonViewWillEnter,
  IonBackButton,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonLoading,
  IonFab,
  IonButton,
  IonIcon,
  useIonLoading,
  useIonAlert,
} from "@ionic/react";
import { arrowForwardCircle } from "ionicons/icons";
import { useState } from "react";

import { useHistory, useParams } from "react-router";
import { getReceiptSum, ReceiptListingType } from ".";
import { DetailButtons } from "../../components/Common/DetailButtons";
import ErrorAlert from "../../components/Common/ErrorAlert";
import useCustomer from "../../hooks/useCustomer";
import { useGetReceiptsLazyQuery } from "../../lib/GraphQL/generated/types";
import { formatWithCommas } from "../../utils/calculator";
import { dateToDisplay } from "../../utils/date";
import { downloadFilePdf } from "../../utils/fileManagement";
import { formatToCurrency } from "../../utils/formatToCurrency";

import "./style.scss";

export type SelectReceiptDetail = ReceiptListingType;

type ReceiptParams = { receiptId: string };

const ReceiptDetail = () => {
  const history = useHistory();
  const customerContext = useCustomer();
  let { receiptId } = useParams<ReceiptParams>();
  const [receiptInfo, setReceiptInfo] = useState<
    SelectReceiptDetail | null | undefined
  >();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [present, dismiss] = useIonLoading();
  const [openAlert] = useIonAlert();

  const [getReceipt, { error, loading }] = useGetReceiptsLazyQuery({
    variables: {
      input: {
        customerId: customerContext.customer?.id,
        id: parseInt(receiptId),
      },
    },
  });

  const middleFunc = async () => {
    present({
      message: "Descargando...",
    });
    try {
      await downloadFilePdf({
        fileName: `receipt-${receiptId}.pdf`,
        uri: `receipt/${receiptId}`,
      });
    } catch (e) {
      openAlert({
        cssClass: "my-css",
        header: "Alert",
        message: String(e as Error),
        buttons: [
          "Cancel",
          { text: "Ok", handler: (d) => console.log("ok pressed") },
        ],
        onDidDismiss: (e) => console.log("did dismiss"),
      });
    }
    dismiss();
  };

  useIonViewWillEnter(async () => {
    try {
      const results = await getReceipt();
      if (error) {
        setErrorMessage(error.message);
        setShowAlert(true);
      } else {
        if (results.data) {
          setReceiptInfo(results.data.receiptPagination.receipts[0]);
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        await setErrorMessage(e.message);
      }
      setShowAlert(true);
    }
  });

  const handleNewReceipt = () => {
    history.replace("/receipts/create");
  };

  const receiptInfoArray = [
    {
      label: "Fecha",
      value: `${dateToDisplay(
        receiptInfo?.date ? new Date(receiptInfo?.date) : new Date()
      )}`,
    },
    {
      label: "Tarjeta",
      value: receiptInfo?.card && `${formatToCurrency(receiptInfo.card)}`,
    },
    {
      label: "Credito",
      value: receiptInfo?.credit && `${formatToCurrency(receiptInfo.credit)}`,
    },
    {
      label: "Efectivo",
      value: receiptInfo?.cash && `${formatToCurrency(receiptInfo.cash)}`,
    },
    {
      label: "Cheque",
      value: receiptInfo?.check && `${formatToCurrency(receiptInfo.check)}`,
    },
    {
      label: "Transferencia",
      value:
        receiptInfo?.transfer && `${formatToCurrency(receiptInfo.transfer)}`,
    },
    {
      label: "Total",
      value: `${receiptInfo && formatToCurrency(getReceiptSum(receiptInfo))}`,
    },
    {
      label: "Observaciones",
      value: receiptInfo?.details && `${receiptInfo.details}`,
    },
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Recibo #{receiptId}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="light">
        <IonCard>
          <DetailButtons
            middle="Descargar"
            middleFunction={middleFunc}
            omit={{ mail: true, edit: true }}
          />
          <IonGrid className="detail-grid">
            <IonRow className="first-row">
              <IonCol className="col-left">Codigo: #{receiptId}</IonCol>
              <IonCol className="col-right">
                Cliente: {customerContext.customer?.id} -
                {customerContext.customer?.name}
              </IonCol>
            </IonRow>

            {receiptInfo && (
              <>
                {receiptInfoArray.map(({ label, value }) =>
                  value ? (
                    <IonRow key={label}>
                      <IonCol className="col-left">{`${label}: ${value}`}</IonCol>
                    </IonRow>
                  ) : null
                )}
                {receiptInfo.distribution && (
                  <IonList className="list">
                    {receiptInfo.distribution.map((debt, key) => {
                      return (
                        <IonCard
                          key={key}
                          className="card-short distribution"
                          style={{ height: "45px" }}
                        >
                          <IonRow>
                            <IonCol className="col-left">
                              ID:{debt.documentId}
                            </IonCol>
                            <IonCol className="col-right">
                              {`${formatToCurrency(debt.amount)}`}
                            </IonCol>
                          </IonRow>
                        </IonCard>
                      );
                    })}
                  </IonList>
                )}
              </>
            )}
          </IonGrid>
        </IonCard>
        <IonFab
          vertical="bottom"
          horizontal="end"
          style={{ paddingBottom: "4rem" }}
        >
          <IonButton onClick={handleNewReceipt}>
            Crear Recibo <IonIcon icon={arrowForwardCircle} />
          </IonButton>
        </IonFab>
      </IonContent>
      <IonLoading isOpen={loading} message={"Cargando..."} />
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
    </IonPage>
  );
};

export default ReceiptDetail;
