import {
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonList,
  IonCard,
  IonLabel,
  IonGrid,
  IonRow,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonButton,
  IonIcon,
  IonLoading,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { SelectClientType } from ".";
import ErrorAlert from "../../components/Common/ErrorAlert";
import { SearchBar } from "../../components/Common/InputBar";
import useCustomer from "../../hooks/useCustomer";
import { useGetCustomersLazyQuery } from "../../lib/GraphQL/generated/types";
import { formatToCurrency } from "../../utils/formatToCurrency";

import "./style.scss";

type ClientSearchType = {
  handleClientSelect: (clientId: number) => void;
  handleClose: (client: SelectClientType | undefined) => void;
};

const ClientSearch = ({
  handleClientSelect,
  handleClose,
}: ClientSearchType) => {
  const customerContext = useCustomer();
  const [clients, setClients] = useState<SelectClientType[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [filter, setFilter] = useState<string>("nombre");
  const [page, setPage] = useState<number>(1);
  const [input, setInput] = useState<string>("");
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const handleChangeInput = (text: string) => {
    setInput(text);
  };

  const [getCustomers, { refetch }] = useGetCustomersLazyQuery({
    variables: { input: {}, limit: 20, ascending: true },
    onCompleted: () => {
      setFirstLoad(false);
    },
  });

  const pushData = (newData: SelectClientType[]) => {
    setClients([...clients, ...newData]);
  };

  const loadData = async (ev: any) => {
    const newResults = await refetch({
      input: { id: null },
      page: page,
    });
    if (newResults.data.customers.nextPage) {
      setPage(newResults.data.customers.nextPage);
      pushData(newResults.data.customers.customers);
      ev.target.complete();
    } else {
      setInfiniteDisabled(true);
    }
  };

  useEffect(() => {
    getCustomers({ variables: { limit: 20 } })
      .then((newResults) => {
        if (newResults.data) {
          pushData(newResults.data.customers.customers);

          if (!newResults.data.customers.nextPage) {
            setInfiniteDisabled(true);
          }
        }
      })
      .catch((e) => {
        if (e instanceof Error) {
          setErrorMessage(e.message);
        }
        setShowAlert(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilterTextHandler = useCallback(
    _.debounce(async (text: string) => {
      setPage(1);
      const newResults = await refetch({
        input:
          filter === "codigo"
            ? { id: parseInt(text) }
            : { name: text === "" ? null : text.toUpperCase() },
        page: 1,
      });
      setClients(newResults.data.customers.customers);
      if (!newResults.data.customers.nextPage) {
        setInfiniteDisabled(true);
      } else {
        setInfiniteDisabled(false);
      }
    }, 500),
    [filter]
  );

  const filterSelectHandler = (value: string) => {
    setFilter(value);
  };

  const handleSelectClient = (client: SelectClientType) => {
    customerContext.setCustomer(client);
    handleClientSelect(client.id);
    handleClose(client);
  };

  useEffect(() => {
    debouncedFilterTextHandler(input);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, filter]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                handleClose(undefined);
              }}
            >
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Buscar Cliente</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <SearchBar
          placeholder="Filtrar Clientes"
          valueHandleChange={handleChangeInput}
          filtersEnabled
          filterHandleChange={filterSelectHandler}
          value={input}
          filterValue={filter}
          filtersOptions={[
            {
              label: "Codigo",
              value: "codigo",
            },
            {
              label: "Nombre",
              value: "nombre",
            },
          ]}
        />
        <IonList className="list">
          {clients.map((client, key) => {
            return (
              <IonCard
                key={key}
                className="card"
                onClick={() => handleSelectClient(client)}
              >
                <IonGrid className="grid-fonts">
                  <IonRow
                    style={{
                      fontWeight: "600",
                      marginBottom: "5px",
                      marginTop: "5px",
                    }}
                    class="ion-justify-content-between"
                  >
                    <IonLabel>{client.name}</IonLabel>
                    <IonLabel>{client.id}</IonLabel>
                  </IonRow>
                  <IonRow class="ion-justify-content-between">
                    <IonLabel>{client.city}</IonLabel>
                    {client.balance > 0 ? (
                      <IonLabel>{formatToCurrency(client.balance)}</IonLabel>
                    ) : null}
                  </IonRow>
                </IonGrid>
              </IonCard>
            );
          })}
        </IonList>
        <IonInfiniteScroll
          onIonInfinite={loadData}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
      <IonLoading isOpen={firstLoad} message={"Cargando..."} />
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
    </>
  );
};

export default ClientSearch;
