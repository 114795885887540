import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Login from "../pages/Auth/Login";

import { routes } from "../constants/routes";
import useUser from "../hooks/useUser";
import useCompany from "../hooks/useCompany";

export const AuthRouter: React.FC = () => {
  const { user } = useUser();
  const { company } = useCompany();

  return (
    <Switch>
      {user && company && <Redirect to={routes.HOME} />}
      <Route path={routes.LOGIN} component={Login} />
    </Switch>
  );
};
