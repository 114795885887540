import { IonAlert, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import MenuOptions from "../components/General/MenuOptions/MenuOptions";
import {
  AUTH_ROUTE,
  CUSTOMER_ROUTE,
  CUSTOMER_SUB_ROUTES,
  PRODUCT_ROUTE,
  PRODUCT_SUB_ROUTES,
  QUOTES_SUB_ROUTES,
  QUOTE_ROUTE,
  RECEIPT_SUB_ROUTES,
  routes,
} from "../constants/routes";
import useCompany from "../hooks/useCompany";
import useUser from "../hooks/useUser";
import { useSyncLazyQuery } from "../lib/GraphQL/generated/types";
import Home from "../pages/Home";
import InventoryPage from "../pages/Inventory";
import QuotesPage from "../pages/Quotes";
import ClientPage from "../pages/Customer";
import { AuthRouter } from "./AuthRouter";
import ProtectedRoute from "./ProtectedRoute";
import CustomerDetail from "../pages/Customer/detail";
import QuoteDetail from "../pages/Quotes/detail";
import ProductDetail from "../pages/Inventory/detail";
import CreateQuote from "../pages/Quotes/Create/create";
import EditQuote from "../pages/Quotes/Edit/edit";
import ReceiptsPage from "../pages/Receipt";
import ReceiptDetail from "../pages/Receipt/detail";
import DebtsPage from "../pages/Receipt/Create";
// import { client } from "../lib/GraphQL/apolloClient";
// import { LOGOUT } from "../context/UserContext";
import { Subject } from "rxjs";

const tokenExpired$ = new Subject();

export function triggerTokenExpired() {
  tokenExpired$.next(undefined);
}

const ProtectedRoutes = () => {
  return (
    <IonSplitPane contentId="main">
      <MenuOptions />
      <IonRouterOutlet id="main">
        <Route path="/" exact={true}>
          <Home />
        </Route>
        <Route path={CUSTOMER_ROUTE} exact={true}>
          <ClientPage />
        </Route>
        <Route path={CUSTOMER_SUB_ROUTES.CLIENT_DETAIL} exact={true}>
          <CustomerDetail />
        </Route>
        <Route path={RECEIPT_SUB_ROUTES.RECEIPT_LISTING} exact={true}>
          <ReceiptsPage />
        </Route>
        <Route path={RECEIPT_SUB_ROUTES.RECEIPT_DETAIL} exact={true}>
          <ReceiptDetail />
        </Route>
        <Route path={RECEIPT_SUB_ROUTES.RECEIPT_CREATION} exact={true}>
          <DebtsPage />
        </Route>
        <Route path={PRODUCT_ROUTE} exact={true}>
          <InventoryPage />
        </Route>
        <Route path={PRODUCT_SUB_ROUTES.PRODUCT_DETAIL} exact={true}>
          <ProductDetail />
        </Route>
        <Route path={QUOTE_ROUTE} exact={true}>
          <QuotesPage />
        </Route>
        <Route path={QUOTES_SUB_ROUTES.QUOTE_DETAIL} exact={true}>
          <QuoteDetail />
        </Route>
        <Route path={QUOTES_SUB_ROUTES.QUOTE_CREATION} exact={true}>
          <CreateQuote />
        </Route>
        <Route path={QUOTES_SUB_ROUTES.QUOTE_EDIT} exact={true}>
          <EditQuote />
        </Route>
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export const AppRouter = () => {
  const [sync] = useSyncLazyQuery({ fetchPolicy: "no-cache" });
  const { setUser } = useUser();
  const { setCompany } = useCompany();
  // const [timeout, setTimeout] = useState(false);
  // const [timer, setTimer] = useState(0);
  const { handleSignOut: handleUserSignOut } = useUser();
  const [userSessionExpired, setUserSessionExpired] = useState(false);

  useEffect(() => {
    const subscription = tokenExpired$.subscribe(() => {
      setUserSessionExpired(true);
      setUser(null);
    });
    const syncFunction = async () => {
      const { data } = await sync();
      setCompany(data?.sync?.company);
      setUser((currentUser) => {
        if (currentUser && !data?.sync?.user) setUserSessionExpired(true);
        return data?.sync?.user;
      });
    };
    syncFunction(); // Run the first time
    const intervalId = setInterval(syncFunction, 1000 * 60 * 5.1); // 4 minutes in milliseconds
    return () => {
      clearInterval(intervalId);
      subscription.unsubscribe();
    };

    // Empty array will prevent setting multiple timeouts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const resetTimer = () => {
  //   setTimer(0);
  // };

  // const timerPlusOne = async () => {
  //   const token = await checkToken();
  //   if (token) {
  //     // resetTimer();
  //   } else {
  //     if (window.location.pathname !== "/auth/login" && timeout === false) {
  //       setTimeout(true);
  //     }
  //   }
  //   if (timeout === false) setTimer(timer + 1);
  //   if (timer === 300) {
  //     setTimeout(true);
  //   }
  // };

  // useEffect(() => {
  //   const onBeforeUnload = async () => {
  //     // Preferences.remove({ key: "authorization" });
  //     await client.mutate({ mutation: LOGOUT });
  //     console.log("onBeforeUnload");
  //   };

  //   window.addEventListener("beforeunload", onBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", onBeforeUnload);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  //   const _fetch = window.fetch;
  //   // window.fetch = function() {
  //   window.fetch = function (...args) {
  //     // return Promise.resolve(_fetch.apply(window, arguments))
  //     return Promise.resolve(_fetch.apply(window, args)).then((resp) => {
  //       resetTimer();
  //       return resp;
  //     });
  //   };

  const handleSignOut = async () => {
    // resetTimer();
    // setTimeout(false);
    setUserSessionExpired(false);
    await handleUserSignOut();
  };

  // useEffect(() => {
  //   const synchronize = async () => {
  //     if (!loading) {
  //       setCompany(data?.sync?.company);
  //       setUser(data?.sync?.user);
  //     }
  //   };
  //   synchronize();
  // }, [data, loading, setUser, setCompany]);

  // // make timer increase each second
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     timerPlusOne();
  //   }, 1000);

  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [timer]);

  return (
    <IonReactRouter>
      <Switch>
        <Route path={AUTH_ROUTE} component={AuthRouter} />
        <ProtectedRoute>
          <ProtectedRoutes />
        </ProtectedRoute>
        <Redirect to={routes.LOGIN} />
      </Switch>
      <IonAlert
        isOpen={userSessionExpired}
        onDidDismiss={() => {
          handleSignOut();
        }}
        header={"Credenciales expiradas"}
        message={
          "La sesión ha expirado tras 5 minutos de inactividad. Vuelve a iniciar sesión."
        }
        buttons={[
          {
            text: "OK",
          },
        ]}
      />
    </IonReactRouter>
  );
};
