import { gql } from "@apollo/client";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { routes } from "../constants/routes";
import { removeToken } from "../data/storage";
import { client } from "../lib/GraphQL/apolloClient";
import { User } from "../lib/GraphQL/generated/types";
import { CustomerContext } from "./CustomerContext";

type UserProviderProps = { children: React.ReactNode };

type UserQuestion =
  | Omit<User, "createdAt" | "updatedAt" | "isActive">
  | undefined
  | null;

export type UserContextType = {
  user?: UserQuestion;
  setUser: React.Dispatch<React.SetStateAction<UserQuestion>>;
  handleSignOut: () => void;
  handleSignIn: (user?: UserQuestion, callback?: () => void) => void;
};

export const UserContext = React.createContext<UserContextType | undefined>(
  undefined
);

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<UserQuestion>(undefined);
  const history = useHistory();
  const customerContext = useContext(CustomerContext);

  const handleSignOut = async () => {
    await client.mutate({ mutation: LOGOUT }).then(() => {
      customerContext?.handleSignOut();
      removeToken();
      setUser(undefined);
    });
  };

  const handleSignIn = (signedUser?: UserQuestion, callback?: () => void) => {
    setUser(signedUser);
    if (callback) {
      callback();
    } else {
      history.push(routes.HOME);
    }
  };

  const value: UserContextType = {
    user,
    setUser,
    handleSignOut,
    handleSignIn,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
