import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonCard,
  IonLabel,
  IonGrid,
  IonRow,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  useIonViewWillEnter,
  IonFab,
  IonFabButton,
  IonIcon,
  IonLoading,
  IonCol,
} from "@ionic/react";
import { add } from "ionicons/icons";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ErrorAlert from "../../components/Common/ErrorAlert";
import { SearchBar } from "../../components/Common/InputBar";
import {
  GetQuotesQuery,
  SaleStatus,
  useGetQuotesLazyQuery,
} from "../../lib/GraphQL/generated/types";
import { dateToDisplay } from "../../utils/date";
import "./style.scss";
import { formatToCurrency } from "../../utils/formatToCurrency";

type StatusType = {
  label: string;
  color: string;
};

type StatusColorType = {
  [key in SaleStatus]: StatusType;
};

export const statusColors: StatusColorType = {
  [SaleStatus.Billing]: {
    label: "Facturación",
    color: "blue",
  },
  [SaleStatus.CreditsAndCharges]: {
    label: "Créditos y cobros",
    color: "red",
  },
  [SaleStatus.Dispatched]: {
    label: "Despachada",
    color: "black",
  },
  [SaleStatus.Inactive]: {
    label: "Inactiva",
    color: "fuchsia",
  },
  [SaleStatus.PriceVerification]: {
    label: "Verificación de precios",
    color: "gold",
  },
  [SaleStatus.Warehouse]: {
    label: "Almacén",
    color: "green",
  },
};

type SelectQuoteType = GetQuotesQuery["quotes"]["quotes"][0];

const QuotesPage = () => {
  const [quotes, setQuotes] = useState<SelectQuoteType[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [filter, setFilter] = useState<string>("cotizacion");
  const [input, setInput] = useState<string>("");
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const handleChangeInput = (text: string) => {
    setInput(text);
  };

  const history = useHistory();

  const [getQuotes, { error, refetch }] = useGetQuotesLazyQuery({
    nextFetchPolicy: "no-cache",
    variables: { limit: 20, input: { id: null }, cursor: 0 },
    onCompleted: () => {
      setFirstLoad(false);
    },
  });

  const pushData = (newData: SelectQuoteType[]) => {
    setQuotes([...quotes, ...newData]);
  };

  const loadData = async (ev: any) => {
    const newResults = await refetch({
      cursor: quotes[quotes.length - 1].id,
    });
    pushData(newResults.data.quotes.quotes);
    ev.target.complete();
    if (!newResults.data.quotes.hasMore) {
      setInfiniteDisabled(true);
    }
  };

  useIonViewWillEnter(async () => {
    setQuotes([]);
    setInput("");
    try {
      const newResults = await getQuotes({ variables: { limit: 20 } });
      if (error) {
        setErrorMessage(error.message);
        setShowAlert(true);
      } else {
        if (newResults.data) {
          pushData(newResults.data.quotes.quotes);
          if (!newResults.data.quotes.hasMore) {
            setInfiniteDisabled(true);
          }
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        await setErrorMessage(e.message);
      }
      setShowAlert(true);
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilterTextHandler = useCallback(
    _.debounce(async (text: string) => {
      const newResults = await refetch({
        input:
          filter === "cotizacion"
            ? { id: parseInt(text) }
            : filter === "idCliente"
            ? { customerId: parseInt(text) }
            : { customerName: text === "" ? null : text.toUpperCase() },
        cursor: 0,
      });
      setQuotes(newResults.data.quotes.quotes);
      setInfiniteDisabled(!newResults.data.quotes.hasMore);
    }, 500),
    [filter]
  );

  const filterSelectHandler = (value: string) => {
    setFilter(value);
  };

  useEffect(() => {
    debouncedFilterTextHandler(input);
  }, [debouncedFilterTextHandler, input]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Pedidos</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="light">
        <div className="search-fixed">
          <SearchBar
            placeholder="Filtrar Pedidos"
            valueHandleChange={handleChangeInput}
            value={input}
            filtersEnabled
            filterHandleChange={filterSelectHandler}
            filterValue={filter}
            filtersOptions={[
              {
                label: "Pedido",
                value: "cotizacion",
              },
              {
                label: "Nombre Cliente",
                value: "nombreCliente",
              },
              {
                label: "ID Cliente",
                value: "idCliente",
              },
            ]}
          />
        </div>

        <IonList className="list">
          {quotes.map((quote, index) => {
            return (
              <IonCard
                key={index}
                className="card-long"
                style={{ height: "auto" }}
                onClick={() => history.push(`/quotes/detail/${quote.id}`)}
              >
                <IonGrid className="grid-fonts">
                  <IonRow
                    style={{
                      fontWeight: "600",
                      marginTop: "5px",
                    }}
                    class="ion-justify-content-between"
                  >
                    <IonCol>
                      <IonLabel>{quote.id}</IonLabel>
                    </IonCol>
                    <IonCol style={{ textAlign: "center" }}>
                      {quote.status ? (
                        <IonLabel
                          style={{ color: statusColors[quote.status].color }}
                        >
                          {statusColors[quote.status].label}
                        </IonLabel>
                      ) : (
                        <IonLabel style={{ color: statusColors.BILLING.color }}>
                          {statusColors.BILLING.label}
                        </IonLabel>
                      )}
                    </IonCol>
                    <IonCol style={{ textAlign: "right" }}>
                      <IonLabel>{quote.customer?.name}</IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow class="ion-justify-content-between">
                    <IonCol style={{ textAlign: "center" }}>
                      <IonLabel>
                        Fecha: {dateToDisplay(new Date(quote.createdAt))}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow class="ion-justify-content-between">
                    <IonCol style={{ textAlign: "left" }}>
                      <IonLabel>
                        Neto: {quote.net ? formatToCurrency(quote.net) : "0"}
                      </IonLabel>
                    </IonCol>
                    <IonCol style={{ textAlign: "center" }}>
                      <IonLabel>Termino Pago: {quote.paymentTerms}</IonLabel>
                    </IonCol>
                    <IonCol style={{ textAlign: "right" }}>
                      <IonLabel>Tipo: {quote.saleType}</IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            );
          })}
        </IonList>

        <IonInfiniteScroll
          onIonInfinite={loadData}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => history.push(`/quotes/new`)}>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </IonContent>
      <IonLoading isOpen={firstLoad} message={"Cargando..."} />
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
    </IonPage>
  );
};

export default QuotesPage;
