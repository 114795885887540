import {
  Http,
  HttpDownloadFileResult,
  HttpOptions,
} from "@capacitor-community/http";
import { Directory } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";
import { checkAccessCode, checkToken } from "../data/storage";
import { apiUri } from "../constants/apiUri";

type DownloadFileProps = {
  fileName: string;
  uri: string;
};

export const downloadFilePdf = async ({ fileName, uri }: DownloadFileProps) => {
  const token = await checkToken();
  const accessCode = await checkAccessCode();

  const options = {
    filePath: `${fileName}.pdf`,
    fileDirectory: Directory.Data,
    url: `${apiUri}/pdf/${uri}`,
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      "X-Company-Access-Code": accessCode ? accessCode : "",
    },
    method: "GET",
  };

  const response: HttpDownloadFileResult = await Http.downloadFile(options);

  // Then read the file
  if (response.path) {
    FileOpener.open({
      filePath: response.path,
      contentType: "application/pdf",
    });
  }
};

type SendFileProps = {
  subject: string;
  message: string;
  to: string;
  uri: string;
};

export const sendMail = async ({
  uri,
  subject,
  message,
  to,
}: SendFileProps) => {
  const token = await checkToken();
  const accessCode = await checkAccessCode();

  let options: HttpOptions = {
    url: `${apiUri}/email/${uri}`,
    headers: {
      "Content-Type": "application/json",
      authorization: token ? `Bearer ${token}` : "",
      "X-Company-Access-Code": accessCode ? accessCode : "",
    },
    method: "POST",
    data: { subject, message, to },
  };

  await Http.post(options);
};
