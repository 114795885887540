import {
  IonButton,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
} from "@ionic/react";
import { Children, useEffect, useState } from "react";
import { useTrail, a } from "@react-spring/web";
import useUser from "../../hooks/useUser";
import useCompany from "../../hooks/useCompany";
import {
  useLoginCompanyMutation,
  useLoginMutation,
} from "../../lib/GraphQL/generated/types";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Virtual } from "swiper";

import "swiper/css";
import "swiper/css/virtual";
import "@ionic/react/css/ionic-swiper.css";

import "./style.css";
import { checkAccessCode, setAccessCode, setToken } from "../../data/storage";
import ErrorAlert from "../../components/Common/ErrorAlert";
import { getVersion } from "../../utils/version";

const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
  const items = Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? 110 : 0,
    from: { opacity: 0, x: 40, height: 0 },
  });
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} className={"trailsText"} style={style}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  );
};

const CompanySlide = () => {
  const [rnc, setRnc] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const swiper = useSwiper();
  const { handleSignIn } = useCompany();

  const [fetchCompany, { loading }] = useLoginCompanyMutation();

  useEffect(() => {
    checkAccessCode().then((code) => {
      if (code) {
        setRnc(code);
        swiper.slideNext();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LoginCompany = async () => {
    if (!(rnc.length >= 9)) {
      setErrorMessage("Rnc debería tener 9 dígitos.");
      setShowAlert(true);
    } else {
      try {
        const results = await fetchCompany({ variables: { rnc } });
        if (!loading) {
          handleSignIn(results.data?.loginCompany.company, () => {
            setAccessCode(rnc);
            if (rnc.length >= 9) {
              swiper.slideNext();
            }
          });
        }
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === "Failed to fetch") {
            await setErrorMessage(`
          <p>El servidor no pudo ser alcanzado.</p>
          <p>Intentalo de nuevo en algunos minutos.</p>
          <p>Si el problema persiste, contáctese con un administrador.</p>`);
          } else {
            await setErrorMessage(error.message);
          }
        }
        setShowAlert(true);
      }
    }
  };

  return (
    <IonGrid>
      <IonRow className="ion-justify-content-center">
        <IonItem>
          <IonLabel position="floating">Company RNC</IonLabel>
          <IonInput
            value={rnc.toUpperCase()}
            placeholder={"RNC"}
            onIonChange={(e) => setRnc(e.detail.value!)}
          />
        </IonItem>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonButton
          onClick={async () => {
            LoginCompany();
          }}
        >
          Login
        </IonButton>
      </IonRow>
      <IonLoading isOpen={loading} message={"Cargando..."} />
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
    </IonGrid>
  );
};

const UserSlide = () => {
  const [cedula, setCedula] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [syncLoading, setSyncLoading] = useState<boolean>(true);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const swiper = useSwiper();
  const { handleSignIn } = useUser();
  const [fetchUser, { error: errorUser, data: userData, loading }] =
    useLoginMutation({
      onCompleted: (data) => {
        if (data.login.errors) {
          setErrorMessage(data.login.errors[0].message);
          setShowAlert(true);
        }
        if (data.login.accessToken) {
          setToken(data.login.accessToken);
        }
      },
    });

  const LoginUser = async () => {
    try {
      const results = await fetchUser({ variables: { cedula, password } });
      userData?.login.accessToken && setToken(userData.login.accessToken);
      handleSignIn(results.data?.login.user, () => {
        if (errorUser) {
          setErrorMessage(errorUser.message);
          setShowAlert(true);
        }
      });
    } catch (error) {
      if (error instanceof Error) {
        await setErrorMessage(error.message);
      }
      setShowAlert(true);
    }
  };

  // async useEffect function
  useEffect(() => {
    const sync = async () => {
      setTimeout(() => {
        setSyncLoading(false);
      }, 1000);
    };
    sync();
  }, []);

  return (
    <IonGrid>
      <IonRow className="ion-justify-content-center">
        <IonItem>
          <IonLabel position="floating">Cedula</IonLabel>
          <IonInput
            value={cedula.toUpperCase()}
            placeholder={"Cedula"}
            onIonChange={(e) => setCedula(e.detail.value!)}
          />
        </IonItem>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonItem>
          <IonLabel position="floating">Contraseña</IonLabel>
          <IonInput
            type="password"
            value={password}
            placeholder={"Contraseña"}
            onIonChange={(e) => setPassword(e.detail.value!)}
          />
        </IonItem>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonButton
          color="secondary"
          onClick={() => {
            setAccessCode("");
            swiper.slidePrev();
          }}
        >
          Back
        </IonButton>
        <IonButton onClick={async () => LoginUser()}>Login</IonButton>
      </IonRow>
      <IonLoading isOpen={loading || syncLoading} message={"Cargando..."} />
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
    </IonGrid>
  );
};

const LoginPage: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen className="login">
        <Trail open={open}>
          <span>PVenta</span>
        </Trail>
        <Swiper
          modules={[Virtual]}
          allowTouchMove={false}
          spaceBetween={10}
          virtual
          style={{ height: "100%" }}
        >
          <SwiperSlide>
            <CompanySlide />
          </SwiperSlide>
          <SwiperSlide>
            <UserSlide />
          </SwiperSlide>
        </Swiper>
        <div
          style={{
            position: "absolute",
            bottom: -5,
            right: 5,
            color: "whitesmoke",
            opacity: 0.8,
          }}
        >
          <p>{getVersion()}</p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
