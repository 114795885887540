import { CreateQuoteDetailInput } from "./../lib/GraphQL/generated/types";

export const calcBruto = (precioUnd: number, cantidad: number) => {
  return precioUnd * cantidad;
};
export const calcDescuento = (bruto: number, porcDesc: number) => {
  if (porcDesc === 0) {
    return 0;
  }
  return bruto * (porcDesc / 100);
};

export const calcItbis = (
  porcItbis: number,
  bruto: number,
  descuento: number
) => {
  return (bruto - descuento) * (porcItbis / 100);
};

export const calcNeto = (bruto: number, descuento: number, itbis: number) => {
  return bruto - descuento + itbis;
};

export const roundNumber = (numero: number) => {
  return Math.round((numero + Number.EPSILON) * 100) / 100;
};

export const formatWithCommas = (value: number, step?: number) => {
  return parseFloat(value.toFixed(step || 2)).toLocaleString("en-US");
};

export const getITBIS = (value: number) => {
  if (value === 1) {
    return "18%";
  }
  if (value === 2) {
    return "0%";
  }

  return "0";
};

export function computeQuoteSummary<
  TQuoteDetail extends CreateQuoteDetailInput
>(details: TQuoteDetail[] | undefined | null) {
  let neto = 0,
    bruto = 0,
    itbis = 0,
    descuento = 0;

  if (!details) {
    return { neto, bruto, itbis, descuento };
  }
  const newDetails = details.map((detail) => {
    const subTotal = detail.quantity! * detail.price!;
    bruto += subTotal;
    const discount = subTotal * (detail.discountPercentage! / 100);
    descuento += discount;
    const tax = (subTotal - discount) * ((detail.taxPercentage! || 0) / 100);
    itbis += tax;
    const total = subTotal - discount + tax;
    neto += total;

    return { subTotal, tax, discount, total, ...detail };
  });

  return { details: newDetails, neto, bruto, itbis, descuento };
}
