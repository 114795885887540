import { Preferences } from "@capacitor/preferences";

const setAccessCode = async (value: string) => {
  await Preferences.set({
    key: "X-Company-Access-Code",
    value: value,
  });
};

const checkAccessCode = async () => {
  const { value } = await Preferences.get({ key: "X-Company-Access-Code" });
  return value;
};

const setToken = async (token: string) => {
  await Preferences.set({
    key: "authorization",
    value: token,
  });
};

const checkToken = async () => {
  const { value } = await Preferences.get({ key: "authorization" });
  return value;
};

const removeToken = async () => {
  await Preferences.remove({ key: "authorization" });
};

export { setToken, checkToken, removeToken, setAccessCode, checkAccessCode };
