import {
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonCol,
  IonLabel,
  IonImg,
  IonThumbnail,
  IonItem,
  IonCard,
} from "@ionic/react";
import CashierImg from "../assets/icons/cashier.png";
import InventoryImg from "../assets/icons/inventory.png";
import TargetImg from "../assets/icons/target.png";
import {
  CUSTOMER_ROUTE,
  PRODUCT_ROUTE,
  QUOTE_ROUTE,
} from "../constants/routes";

import "./home.module.css";

type MenuButton = {
  url: string;
  image: string;
  title: string;
};
const Home: React.FC = () => {
  const options: MenuButton[] = [
    {
      url: QUOTE_ROUTE,
      image: CashierImg,
      title: "Pedidos",
    },
    {
      url: PRODUCT_ROUTE,
      image: InventoryImg,
      title: "Inventario",
    },
    {
      url: CUSTOMER_ROUTE,
      image: TargetImg,
      title: "Clientes",
    },
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Home</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="light">
        <IonGrid>
          <IonRow>
            {options.map((menuOption, i) => (
              <IonCol key={i}>
                <IonCard className="ion-card">
                  <IonItem routerLink={menuOption.url}>
                    <IonThumbnail slot="start">
                      <IonImg src={menuOption.image} />
                    </IonThumbnail>
                    <IonLabel>{menuOption.title}</IonLabel>
                  </IonItem>
                </IonCard>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
