import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { search, closeCircleSharp } from "ionicons/icons";

import ErrorAlert from "../../../components/Common/ErrorAlert";
import {
  Product,
  QuoteDetail,
  SalePrices,
} from "../../../lib/GraphQL/generated/types";

import {
  calcBruto,
  calcDescuento,
  calcItbis,
  calcNeto,
  roundNumber,
} from "../../../utils/calculator";

import "../style.scss";
import useCompany from "../../../hooks/useCompany";
import { useEffect, useState } from "react";
import { formatToCurrency } from "../../../utils/formatToCurrency";

type EditDetailProps = {
  salePrice: SalePrices;
  detail: QuoteDetail;
  handleUpdateDetail: (detail: QuoteDetail) => void;
  handleClose: () => void;
};

const EditDetail = ({
  salePrice,
  detail,
  handleClose,
  handleUpdateDetail,
}: EditDetailProps) => {
  const { company } = useCompany();
  const getPriceTag = (product: Product) => {
    if (salePrice === SalePrices.Price0) {
      return product.price0;
    }
    if (salePrice === SalePrices.Price1) {
      return product.price1;
    }
    return product.price2;
  };

  const [tempProduct, setTempProduct] = useState<QuoteDetail>(
    JSON.parse(JSON.stringify(detail))
  );
  const [precioElegido, setPrecioElegido] = useState<number>(detail.price || 0);
  const [cantidad, setCantidad] = useState<number>(detail.quantity || 1);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage] = useState<string>("");
  const [observations, setObservations] = useState<string>(
    detail.observations || ""
  );
  const [makeZero, setMakeZero] = useState<boolean>(
    detail.price === 0 ? true : false
  );
  let itbisBool = false;

  let bruto = precioElegido ? calcBruto(precioElegido, cantidad) : 0;
  let descuento = calcDescuento(bruto, 0);
  let itbis = itbisBool
    ? roundNumber(calcItbis(company?.config?.tax!, bruto, descuento))
    : 0;
  let neto = calcNeto(bruto, descuento, itbis);

  const toggleZero = () => {
    if (!makeZero) {
      setPrecioElegido(0);
      bruto = 0;
      descuento = 0;
      itbis = 0;
      neto = 0;
    } else {
      if (salePrice) {
        if (salePrice) {
          setPrecioElegido(detail.product ? getPriceTag(detail.product) : 0);
        }
      } else {
        setPrecioElegido(precioElegido);
      }
      bruto = 0 ? calcBruto(0, cantidad) : 0;
      descuento = calcDescuento(bruto, 0);
      itbis = itbisBool
        ? roundNumber(calcItbis(company?.config?.tax!, bruto, descuento))
        : 0;
      neto = calcNeto(bruto, descuento, itbis);
    }
    setMakeZero(!makeZero);
  };

  const saveFunction = async () => {
    handleUpdateDetail(tempProduct);
    handleClose();
  };

  useEffect(() => {
    const parsedProduct: QuoteDetail = {
      productId: detail.productId,
      quoteId: tempProduct?.quoteId!,
      order: tempProduct?.order!,
      price: precioElegido,
      quantity: cantidad,
      observations: observations,
      taxPercentage: tempProduct?.taxPercentage,
      product: tempProduct?.product,
    };
    setTempProduct(parsedProduct);
  }, [
    setCantidad,
    setObservations,
    precioElegido,
    detail.product?.name,
    detail.productId,
    tempProduct?.quoteId,
    tempProduct?.order,
    tempProduct?.taxPercentage,
    cantidad,
    observations,
    tempProduct?.product,
  ]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Edit Detail</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonContent fullscreen>
          <IonGrid>
            <IonRow>
              <IonCol class="ion-text-start ion-align-self-center" size="8">
                <IonText>
                  <h3>{detail.product?.name}</h3>
                </IonText>
              </IonCol>
              <IonCol class="ion-text-end ion-align-self-center">
                <IonText>
                  <h2>{`#${detail.productId}`}</h2>
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonItem>
            <div style={{ width: "70%" }}>
              <IonLabel>Cantidad</IonLabel>
            </div>
            <IonInput
              type="number"
              value={cantidad}
              min="0"
              placeholder="Enter Number"
              onIonChange={(e) => setCantidad(parseInt(e.detail.value!))}
            />
          </IonItem>
          <IonItem>
            <div style={{ width: "70%" }}>
              <IonLabel>Precio</IonLabel>
            </div>
            <IonButton onClick={toggleZero}>
              <IonIcon icon={makeZero ? search : closeCircleSharp} />
            </IonButton>
            <div style={{ marginLeft: "25px" }}>
              <IonText>{formatToCurrency(precioElegido)}</IonText>
            </div>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Observaciones</IonLabel>
            <IonTextarea
              value={observations}
              maxlength={50}
              onIonChange={(e) => setObservations(e.detail.value!)}
            />
          </IonItem>

          {precioElegido >= 0 ? (
            <>
              <IonItem>
                <IonLabel>Subtotal</IonLabel>
                <IonLabel>{formatToCurrency(bruto)}</IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel>Impuestos</IonLabel>
                <IonLabel>{formatToCurrency(itbis)}</IonLabel>
              </IonItem>
              <IonItem>
                <IonLabel>Total</IonLabel>
                <IonLabel>{formatToCurrency(neto)}</IonLabel>
              </IonItem>
              <IonGrid>
                <IonRow className="ion-justify-content-evenly">
                  <IonButton color="light" onClick={handleClose}>
                    Atrás
                  </IonButton>
                  <IonButton
                    onClick={() => {
                      saveFunction();
                    }}
                  >
                    Guardar
                  </IonButton>
                </IonRow>
              </IonGrid>
            </>
          ) : null}
          <ErrorAlert
            isOpen={showAlert}
            dismiss={() => setShowAlert(false)}
            message={errorMessage}
          />
        </IonContent>
      </IonContent>
    </>
  );
};

export default EditDetail;
