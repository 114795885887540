import { gql } from "@apollo/client";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { routes } from "../constants/routes";
import { client } from "../lib/GraphQL/apolloClient";
import { Company, Config } from "../lib/GraphQL/generated/types";

type CompanyProviderProps = { children: React.ReactNode };

type CompanyQuestion =
  | (Omit<Company, "config"> & { config?: Pick<Config, "tax"> | null })
  | undefined
  | null;

export type CompanyContextType = {
  company?: CompanyQuestion;
  setCompany: React.Dispatch<React.SetStateAction<CompanyQuestion>>;
  handleSignOut: () => void;
  handleSignIn: (company?: CompanyQuestion, callback?: () => void) => void;
};

export const CompanyContext = React.createContext<
  CompanyContextType | undefined
>(undefined);

const LOGOUT_COMPANY = gql`
  mutation LogoutCompany {
    logoutCompany
  }
`;

const CompanyProvider = ({ children }: CompanyProviderProps) => {
  const [company, setCompany] = useState<CompanyQuestion>(undefined);
  const history = useHistory();

  const handleSignOut = async () => {
    await client.mutate({ mutation: LOGOUT_COMPANY });
    setCompany(undefined);
  };

  const handleSignIn = (
    selectedCompany?: CompanyQuestion,
    callback?: () => void
  ) => {
    setCompany(selectedCompany);
    if (callback) {
      callback();
    } else {
      history.push(routes.HOME);
    }
  };

  const value: CompanyContextType = {
    company,
    setCompany,
    handleSignOut,
    handleSignIn,
  };

  return (
    <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>
  );
};

export default CompanyProvider;
