import { INITIAL_STATE, StateType } from "./state";
import { createContext, useReducer } from "react";
import React from "react";
import { appReducer } from "./reducer";
import { assignDispatch, setQuote } from "./actions";
type AppProviderProps = { children: React.ReactNode };

export const AppContext = createContext<StateType>(INITIAL_STATE);

export const AppProvider = ({ children }: AppProviderProps) => {
  const [state, dispatch] = useReducer(appReducer, INITIAL_STATE);
  const actions = assignDispatch(dispatch, { setQuote });
  const value = {
    ...state,
    ...actions,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
