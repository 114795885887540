import {
  CreateQuoteMutation,
  UpdateQuoteMutation,
  CreateQuoteInput,
  GetQuoteDetailQuery,
} from "./../lib/GraphQL/generated/types";
import { Quote, QuoteInput } from "../lib/GraphQL/generated/types";

export type QuoteState =
  | Quote
  | GetQuoteDetailQuery
  | CreateQuoteMutation["createQuote"]
  | UpdateQuoteMutation["updateQuote"]["quote"]
  | CreateQuoteInput
  | QuoteInput;

export type StateType = {
  quote?: QuoteState;
};

export const INITIAL_STATE = {};
