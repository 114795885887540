import { GetProductDetailQuery, SalePrices } from "../lib/GraphQL/generated/types";

export const getPrecioVenta = (product: GetProductDetailQuery["product"], priceType: SalePrices) => {
    if (product) {
      if (priceType === SalePrices.SalePrice) {
        return product["salePrice"];
      } else {
        return product[
          priceType.toLowerCase() as "price0" | "price1" | "price2"
        ];
      }
    }
    return 0;
  };