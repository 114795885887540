import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonCard,
  IonLabel,
  IonGrid,
  IonRow,
  useIonViewWillEnter,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonCol,
  IonLoading,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { search } from "ionicons/icons";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import ErrorAlert from "../../components/Common/ErrorAlert";
import { SearchBar } from "../../components/Common/InputBar";
import { differenceInDays } from "date-fns";
import {
  CUSTOMER_ROUTE,
  RECEIPT_ROUTE,
  RECEIPT_SUB_ROUTES,
} from "../../constants/routes";
import useCustomer from "../../hooks/useCustomer";
import {
  Receipt,
  useGetReceiptsLazyQuery,
} from "../../lib/GraphQL/generated/types";

import "./style.scss";
import { dateToDisplay } from "../../utils/date";
import { formatToCurrency } from "../../utils/formatToCurrency";

const balanceKeys = ["card", "cash", "credit", "check", "transfer"];

export type ReceiptListingType = Omit<
  Receipt,
  "return" | "commission" | "posted" | "redepositCheck" | "returnRetained"
>;

export const getReceiptSum = (receipt: ReceiptListingType) => {
  let sum = 0;
  balanceKeys.forEach((key) => {
    sum += receipt[key as keyof ReceiptListingType];
  });
  return sum;
};

const ReceiptsPage = () => {
  const customerContext = useCustomer();
  const [receipts, setReceipts] = useState<ReceiptListingType[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [input, setInput] = useState<string>();
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const handleChangeInput = (text: string) => {
    setInput(text);
  };

  const history = useHistory();
  const mounted = useRef(false);

  const [getReceipts, { error, refetch }] = useGetReceiptsLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      input: { customerId: customerContext.customer?.id },
      limit: 20,
    },
    onCompleted: () => {
      setFirstLoad(false);
    },
  });

  useEffect(() => {
    mounted.current = true;
    if (customerContext.customer === undefined) {
      history.replace(CUSTOMER_ROUTE);
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushData = (newData: ReceiptListingType[]) => {
    setReceipts([...receipts, ...newData]);
  };
  const loadData = async (ev: any) => {
    if (receipts.length === 0) {
      const newResults = await refetch({
        input: { customerId: customerContext.customer?.id },
        cursor: receipts[receipts.length - 1].id,
      });

      pushData(
        newResults.data.receiptPagination.receipts.sort((a, b) => {
          return differenceInDays(new Date(a.date), new Date(b.date));
        })
      );
      ev.target.complete();
      if (!newResults.data.receiptPagination.hasMore) {
        setInfiniteDisabled(true);
      }
    }
  };

  useIonViewWillEnter(async () => {
    setReceipts([]);
    setInput("");
    try {
      const newResults = await getReceipts({ variables: { limit: 20 } });
      if (error) {
        setErrorMessage(error.message);
        setShowAlert(true);
      } else {
        if (newResults.data && mounted.current) {
          pushData(newResults.data.receiptPagination.receipts);
          if (!newResults.data.receiptPagination.hasMore) {
            setInfiniteDisabled(true);
          }
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        await setErrorMessage(e.message);
      }
      setShowAlert(true);
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilterTextHandler = useCallback(
    _.debounce(async (text: string) => {
      const newResults = await refetch({
        input: {
          id: parseInt(text) || undefined,
          customerId: customerContext.customer?.id,
        },
        cursor: 0,
      });
      setReceipts(newResults.data.receiptPagination.receipts);
      if (!newResults.data.receiptPagination.hasMore) {
        setInfiniteDisabled(true);
      } else {
        setInfiniteDisabled(false);
      }
    }, 500),
    [input]
  );

  useEffect(() => {
    input && debouncedFilterTextHandler(input);
  }, [debouncedFilterTextHandler, input]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <IonTitle
              style={{
                width: "80%",
                fontSize: "3.2vmin",
              }}
            >{`Recibos de ${customerContext.customer?.name}`}</IonTitle>
            <IonButton
              onClick={() => history.replace(CUSTOMER_ROUTE)}
              style={{ alignText: "center", padding: "5px" }}
            >
              <IonIcon icon={search} />
            </IonButton>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <div className="search-fixed">
          <SearchBar
            placeholder="Filtrar Recibos"
            valueHandleChange={handleChangeInput}
            value={input}
            button
            buttonLabel="Crear Recibo"
            buttonFunction={() =>
              history.replace(RECEIPT_SUB_ROUTES.RECEIPT_CREATION)
            }
          />
        </div>
        <IonList className="list">
          {receipts.map((receipt, key) => {
            return (
              <IonCard
                key={key}
                className="card-short"
                style={{ height: "60px" }}
                onClick={() =>
                  history.push(`${RECEIPT_ROUTE}/detail/${receipt.id}`)
                }
              >
                <IonGrid className="grid-fonts">
                  <IonRow
                    style={{
                      fontWeight: "600",
                      marginBottom: "5px",
                      marginTop: "5px",
                    }}
                    class="ion-justify-content-between"
                  >
                    <IonCol>
                      <IonLabel style={{ fontWeight: "800", fontSize: "18px" }}>
                        {receipt.id}
                      </IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel>{`Fecha: ${dateToDisplay(
                        new Date(receipt.date)
                      )}`}</IonLabel>
                    </IonCol>
                    <IonCol>
                      <IonLabel>{`Total: ${formatToCurrency(
                        getReceiptSum(receipt)
                      )}`}</IonLabel>
                    </IonCol>
                  </IonRow>
                  <IonRow class="ion-justify-content-between"></IonRow>
                </IonGrid>
              </IonCard>
            );
          })}
        </IonList>
        <IonInfiniteScroll
          onIonInfinite={loadData}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
      <IonLoading isOpen={firstLoad} message={"Cargando..."} />
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
    </IonPage>
  );
};

export default ReceiptsPage;
