import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { routes } from "../constants/routes";
import useCompany from "../hooks/useCompany";
import useUser from "../hooks/useUser";

type ProtectedRouteProps = {
  children: React.ReactNode;
} & RouteProps;

const ProtectedRoute = ({ children, ...rest }: ProtectedRouteProps) => {
  const { user } = useUser();
  const { company } = useCompany();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user && company ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
