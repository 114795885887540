import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonNote,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";

import { pencil, search, trash } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import ErrorAlert from "../../../components/Common/ErrorAlert";
import {
  GetQuoteDetailQuery,
  QuoteDetail,
  useGetQuoteDetailLazyQuery,
  useUpdateQuoteMutation,
  SaleType,
  SalePrices,
  useGetCustomerTypesQuery,
} from "../../../lib/GraphQL/generated/types";
import { computeQuoteSummary, getITBIS } from "../../../utils/calculator";
import ClientSearch from "../../Customer/search";
import SelectProducts from "./selectEdit";

import "../style.scss";
import useCustomer from "../../../hooks/useCustomer";
import { getPrecioVenta } from "../../../utils/getSalePrice";
import EditDetail from "./editDetail";
import { formatToCurrency } from "../../../utils/formatToCurrency";

type QuoteParams = { quoteId: string };

const EditQuote = () => {
  let { quoteId } = useParams<QuoteParams>();
  const history = useHistory();
  const localCustomer = useCustomer();
  const [client, setClient] = useState<number>(localCustomer.customer?.id || 0);
  const [openSearchClient, setOpenSearchClient] = useState<boolean>(false);
  const [openAddProduct, setOpenAddProduct] = useState<boolean>(false);
  const [openEditDetail, setOpenEditDetail] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<QuoteDetail>();

  const [getQuote, { error: errorGetQuote }] = useGetQuoteDetailLazyQuery({
    variables: { id: parseInt(quoteId) },
    fetchPolicy: "no-cache",
  });

  const { data: customerTypesData, loading: customerTypesLoading } =
    useGetCustomerTypesQuery({
      fetchPolicy: "cache-first",
    });

  const [updateQuote, { error: errorUpdate, loading: updateLoading }] =
    useUpdateQuoteMutation();

  const [quote, setQuote] = useState<GetQuoteDetailQuery["quote"]>();
  const [detalle, setDetalle] = useState<QuoteDetail[]>([]);

  const nowDetail = () => detalle;

  useIonViewWillEnter(async () => {
    try {
      if (quote) {
        return;
      }
      const results = await getQuote();
      if (errorGetQuote) {
        setErrorMessage(errorGetQuote.message);
        setShowAlert(true);
      } else {
        if (results.data) {
          setQuote(results.data.quote);
          setDetalle(results.data.quote?.details! as any);
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        await setErrorMessage(e.message);
      }
      setShowAlert(true);
    }
  });

  const newDetails = () =>
    detalle.map(({ __typename, product, ...detail }) => detail);

  const saveQuote = async () => {
    const newQuote = {
      id: parseInt(quoteId),
      customerId: quote?.customerId,
      paymentTerms: quote?.paymentTerms,
      saleType: quote?.saleType,
      observations: quote?.observations,
      details: newDetails(),
    };
    try {
      await updateQuote({ variables: { input: newQuote } });
      if (errorUpdate) {
        await setErrorMessage(errorUpdate.message);
        setShowAlert(true);
      } else {
        history.goBack();
      }
    } catch (e) {
      if (e instanceof Error) {
        await setErrorMessage(e.message);
      }
      setShowAlert(true);
    }
  };

  const handleDetailArray = (arreglo: QuoteDetail[]) => {
    setDetalle(arreglo);
  };

  const handleOpenEditDetail = (order: number) => {
    if (details) {
      setSelectedItem(details.find((detail) => detail.order === order));
      setOpenEditDetail(true);
    }
  };

  const reset = () => {
    let newQuote = {
      ...quote!,
      TipoVenta: SaleType.Cr,
      TerminosPago: 0,
      Observaciones: "",
    };
    setQuote(newQuote);
    setDetalle([]);
  };

  const getPriceCategory = (customerTypeId: number) => {
    if (customerTypesData && customerTypeId) {
      const customerType = customerTypesData.getCustomerTypes.find(
        (customerType) => customerType.id === customerTypeId
      );
      return customerType?.salePrice;
    }
    return SalePrices.SalePrice;
  };

  const handleChange =
    (
      name:
        | "saleType"
        | "clientId"
        | "saleTerms"
        | "observations"
        | "details"
        | "discountPercent"
    ) =>
    (event: CustomEvent) => {
      setQuote((prevQuote) => {
        let newQuote = { ...prevQuote! };
        switch (name) {
          case "saleType":
            newQuote.saleType = event.detail.value!;
            break;
          case "saleTerms":
            event.detail.value &&
              (newQuote.paymentTerms = parseInt(event.detail.value));
            break;
          case "clientId":
            event.detail.value &&
              (newQuote.customerId = parseInt(event.detail.value));
            break;
          case "observations":
            newQuote.observations = event.detail.value!;
            break;
          case "details":
            newQuote.details = detalle;
            break;
          case "discountPercent":
            event.detail.value &&
              (newQuote.discount = parseInt(event.detail.value!));
            break;
          default:
            break;
        }
        return newQuote;
      });
    };

  const { bruto, neto, itbis, details } = computeQuoteSummary(detalle);

  const updateDetail = (detail: QuoteDetail) => {
    const nuevoDetalle = detalle.map((value) => {
      if (value.order === detail.order) {
        return { ...value, ...detail };
      }
      return value;
    });
    console.log(nuevoDetalle);
    handleDetailArray(nuevoDetalle);
  };

  const DeleteDetail = async (order: number) => {
    const nuevoDetalle = detalle
      .filter((value) => value.order !== order)
      .map((value, index) => {
        return { ...value, order: index + 1 };
      });
    handleDetailArray(nuevoDetalle);
  };

  useEffect(() => {
    if (!customerTypesLoading) {
      setQuote((quote) => {
        if (quote?.customerId === client) return;

        setDetalle((prev) =>
          prev.map((detalle) => {
            const currentProduct = detalle.product!;
            const currentCustomerType =
              customerTypesData!.getCustomerTypes.find(
                (type) => type.id === localCustomer.customer?.customerTypeId
              );

            const currentCustomersProductPrice = getPrecioVenta(
              currentProduct,
              currentCustomerType!.salePrice!
            );

            return {
              ...detalle,
              price:
                currentCustomersProductPrice || currentProduct.salePrice! || 0,
            };
          })
        );

        return {
          ...quote!,
          customerId: client,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Editar Pedido</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonCard className="edit-card">
          <IonItem>
            <IonLabel>Cliente</IonLabel>
            <IonInput
              value={localCustomer.customer?.id}
              placeholder="Escribe el cliente"
              onIonChange={handleChange("clientId")}
            />
            <IonNote style={{ alignSelf: "center", paddingRight: "1rem" }}>
              {localCustomer.customer?.name}
            </IonNote>
            <IonButton onClick={() => setOpenSearchClient(true)}>
              <IonIcon icon={search} />
            </IonButton>
          </IonItem>
          <IonItem>
            <IonLabel>Tipo de Venta</IonLabel>
            <IonSelect
              value={quote?.saleType}
              placeholder="Select One"
              onIonChange={handleChange("saleType")}
            >
              <IonSelectOption value="CO">Contado</IonSelectOption>
              <IonSelectOption value="CR">Credito</IonSelectOption>
            </IonSelect>
          </IonItem>

          {quote?.saleType === "CR" && (
            <IonItem>
              <IonLabel>Terminos de Pago</IonLabel>
              <IonInput
                type="number"
                value={quote?.paymentTerms}
                placeholder="Enter Number"
                min={"0"}
                max={"60"}
                onIonChange={handleChange("saleTerms")}
              />
            </IonItem>
          )}
          <IonItem>
            <IonLabel position="floating">Observaciones</IonLabel>
            <IonTextarea
              value={quote?.observations}
              maxlength={50}
              onIonChange={handleChange("observations")}
            />
          </IonItem>
          <IonItem>
            <IonLabel>Desglose de productos</IonLabel>
            <IonButton onClick={() => setOpenAddProduct(true)}>
              <IonIcon icon={search} />
            </IonButton>
          </IonItem>
          <IonList
            className="list list-edit"
            style={{ height: "46vh", overflow: "scroll" }}
          >
            {details?.map((fila, index) => (
              <IonCard key={index} className="select-card">
                <IonGrid className="grid-fonts edit-grid">
                  <div className="edit-row-div">
                    <IonRow
                      style={{
                        fontWeight: "600",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                      class="ion-justify-content-between"
                    >
                      <IonLabel>{`#${fila.order} ${
                        //@ts-ignore
                        fila.product.name || ""
                      }`}</IonLabel>
                      <IonLabel>Codigo: #{fila.productId}</IonLabel>
                    </IonRow>
                    <IonRow class="ion-justify-content-between">
                      <IonLabel>{`Cantidad: ${fila.quantity}`}</IonLabel>
                      <IonLabel>{`Total: ${formatToCurrency(
                        fila.total
                      )}`}</IonLabel>
                    </IonRow>
                  </div>
                  <div className="button-div ">
                    <IonButton
                      onClick={() => {
                        handleOpenEditDetail(fila.order);
                      }}
                    >
                      <IonIcon icon={pencil} />
                    </IonButton>
                  </div>
                  <div className="button-div">
                    <IonButton
                      onClick={
                        //@ts-ignore
                        () => DeleteDetail(fila.order)
                      }
                    >
                      <IonIcon icon={trash} />
                    </IonButton>
                  </div>
                </IonGrid>
              </IonCard>
            ))}
          </IonList>
        </IonCard>
        <div className="price-fixed">
          <IonCard className="price-card">
            <IonGrid>
              <IonRow className="ion-justify-content-evenly">
                <IonButton onClick={reset} color="light">
                  Limpiar
                </IonButton>

                <IonButton disabled={!quote?.customerId} onClick={saveQuote}>
                  Grabar
                </IonButton>
              </IonRow>
            </IonGrid>
            <IonGrid>
              <IonRow className="row-price">
                <IonCol className="col-center">
                  <div className="col-price ">
                    <IonLabel>Subtotal</IonLabel>
                    <IonTitle>{formatToCurrency(bruto)}</IonTitle>
                  </div>
                </IonCol>
                <IonCol className="col-center">
                  <div className="col-price ">
                    <IonLabel>Itbis</IonLabel>
                    <IonTitle>{getITBIS(itbis)}</IonTitle>
                  </div>
                </IonCol>
                <IonCol className="col-center">
                  <div className="col-price ">
                    <IonLabel>Total</IonLabel>
                    <IonTitle>{formatToCurrency(neto)}</IonTitle>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCard>
        </div>
        <ErrorAlert
          isOpen={showAlert}
          dismiss={() => setShowAlert(false)}
          message={errorMessage}
        />
      </IonContent>
      <IonModal isOpen={openEditDetail}>
        <EditDetail
          salePrice={quote?.customer?.type?.salePrice || SalePrices.SalePrice}
          detail={selectedItem || detalle![0]}
          handleUpdateDetail={updateDetail}
          handleClose={() => setOpenEditDetail(false)}
        />
      </IonModal>
      <IonModal isOpen={openSearchClient}>
        <ClientSearch
          handleClientSelect={setClient}
          handleClose={() => setOpenSearchClient(false)}
        />
      </IonModal>
      <IonModal isOpen={openAddProduct}>
        <SelectProducts
          priceType={
            getPriceCategory(localCustomer.customer?.customerTypeId!) ||
            SalePrices.SalePrice
          }
          detail={nowDetail()}
          handleAdd={async (cantidad, product, tax, precio) => {
            const nuevoProducto = {
              quoteId: parseInt(quoteId),
              quantity: cantidad,
              discountPercentage: 0,
              price: precio,
              taxPercentage: tax,
              productId: product.id,
              product: product,
              order: detalle.length + 1,
            } as QuoteDetail;
            const nuevoDetalle = [...detalle, nuevoProducto] as QuoteDetail[];
            if (nuevoDetalle) {
              handleDetailArray(nuevoDetalle);
            }
          }}
          handleClose={() => setOpenAddProduct(false)}
        />
      </IonModal>
      <IonLoading
        isOpen={updateLoading || customerTypesLoading}
        message={"Cargando..."}
      />
    </IonPage>
  );
};

export default EditQuote;
