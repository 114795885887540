import { Quote, QuoteDetail, Customer } from "./../lib/GraphQL/generated/types";
export const Actions = {
  SET_CLIENT: "SET_CLIENT",
  SET_DETAILS: "SET_DETAILS",
  SET_ONE_DETAIL: "SET_ONE_DETAIL",
  SET_QUOTE: "SET_QUOTE",
};

export type ActionsType =
  | { type: "SET_CLIENT"; payload: Customer }
  | { type: "SET_DETAILS"; payload: QuoteDetail[] }
  | { type: "SET_ONE_DETAIL"; payload: QuoteDetail }
  | { type: "SET_QUOTE"; payload: Quote };

type ActionCreators = { [k: string]: (stuff: any) => ActionsType };
export function assignDispatch<T extends ActionCreators>(
  dispatch: Function,
  actionCreators: T
) {
  let actionCreatorsWithDispatch: {
    [key in keyof T]: (
      payload: Parameters<typeof actionCreators[key]>[0]
    ) => any;
  } = {
    ...actionCreators,
  };
  Object.keys(actionCreators).forEach((key: keyof T) => {
    const currentActionCreator = actionCreators[key];
    actionCreatorsWithDispatch[key] = (stuff: any) =>
      dispatch(currentActionCreator(stuff));
  });

  return {
    ...actionCreatorsWithDispatch,
  };
}

export const setQuote = (quote: Quote): ActionsType => ({
  type: "SET_QUOTE",
  payload: quote,
});
