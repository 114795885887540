import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type CodTransacCodigoLocationInput = {
  location: Scalars['String'];
  productId: Scalars['Int'];
  receiptId: Scalars['Int'];
};

export type Company = {
  __typename?: 'Company';
  config?: Maybe<Config>;
  id: Scalars['ID'];
  name: Scalars['String'];
  primaryTenant?: Maybe<Tenant>;
  rnc: Scalars['String'];
  tenants?: Maybe<Array<Tenant>>;
};

export type CompanyLoginInput = {
  accessCode: Scalars['String'];
};

export type CompanyRegistrationInput = {
  database: Scalars['String'];
  host: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type CompanyResponse = {
  __typename?: 'CompanyResponse';
  company?: Maybe<Company>;
  errors?: Maybe<Array<FieldError>>;
};

export type Config = {
  __typename?: 'Config';
  RNC: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  companyTypeId: Scalars['Int'];
  digitalSignature: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tax: Scalars['Float'];
  tax1: Scalars['Float'];
};

export type CreateQuoteDetailInput = {
  discountPercentage?: InputMaybe<Scalars['Float']>;
  observations?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productId: Scalars['Int'];
  quantity?: InputMaybe<Scalars['Int']>;
  taxPercentage?: InputMaybe<Scalars['Float']>;
};

export type CreateQuoteDetailWithQuoteIdInput = {
  discountPercentage?: InputMaybe<Scalars['Float']>;
  observations?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productId: Scalars['Int'];
  quantity?: InputMaybe<Scalars['Int']>;
  quoteId: Scalars['Int'];
  taxPercentage?: InputMaybe<Scalars['Float']>;
};

export type CreateQuoteInput = {
  customerId?: InputMaybe<Scalars['Int']>;
  details?: InputMaybe<Array<CreateQuoteDetailInput>>;
  observations?: InputMaybe<Scalars['String']>;
  paymentTerms?: InputMaybe<Scalars['Int']>;
  saleType?: InputMaybe<SaleType>;
};

export type CreateReceiptInput = {
  card?: InputMaybe<Scalars['Float']>;
  cash?: InputMaybe<Scalars['Float']>;
  check?: InputMaybe<Scalars['Float']>;
  comission?: InputMaybe<Scalars['Float']>;
  credit?: InputMaybe<Scalars['Float']>;
  customerId: Scalars['Int'];
  details?: InputMaybe<Scalars['String']>;
  discount?: InputMaybe<Scalars['Float']>;
  payment: Array<Payment>;
  redepositCheck?: InputMaybe<Scalars['Float']>;
  return?: InputMaybe<Scalars['Float']>;
  returnRetained: Scalars['Boolean'];
  transfer?: InputMaybe<Scalars['Float']>;
};

export type Customer = {
  __typename?: 'Customer';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  balance: Scalars['Float'];
  businessName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  creditAccount?: Maybe<Scalars['String']>;
  creditLimit?: Maybe<Scalars['Float']>;
  customerTypeId: Scalars['Int'];
  debitAccount?: Maybe<Scalars['String']>;
  debt?: Maybe<Array<Debt>>;
  details?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  idCard?: Maybe<Scalars['String']>;
  lastPaymentDate?: Maybe<Scalars['DateTime']>;
  lastPurchaseDate?: Maybe<Scalars['DateTime']>;
  lastVisitedAt?: Maybe<Scalars['DateTime']>;
  legalRepresentative?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentTerms?: Maybe<Scalars['Int']>;
  phone1?: Maybe<Scalars['String']>;
  phone2?: Maybe<Scalars['String']>;
  restricted: Scalars['Boolean'];
  sector?: Maybe<Scalars['String']>;
  sellerId: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
  taxable: Scalars['Boolean'];
  type?: Maybe<CustomerType>;
  visitAt?: Maybe<Scalars['DateTime']>;
  zone: Scalars['Int'];
};


export type CustomerDebtArgs = {
  id: Scalars['Int'];
};

export type CustomerInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CustomerType = {
  __typename?: 'CustomerType';
  fee?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  salePrice: SalePrices;
};

export type Debt = {
  __typename?: 'Debt';
  DGIIControl?: Maybe<Scalars['String']>;
  advanceTax: Scalars['Float'];
  balance: Scalars['Float'];
  documentId: Scalars['Int'];
  documentType: DebtDocumentType;
  net: Scalars['Float'];
  payment: Scalars['Float'];
  subtotal: Scalars['Float'];
  tax: Scalars['Float'];
};

/** Types of documents accepted for debt. */
export enum DebtDocumentType {
  Fa = 'FA',
  Nd = 'ND'
}

export type DeleteQuoteDetailInput = {
  itemNumber: Scalars['Int'];
  quoteId: Scalars['Int'];
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type IdCodeAmountLotsInput = {
  amount?: InputMaybe<Scalars['Float']>;
  id: Scalars['Float'];
  lots?: InputMaybe<Array<LotsInput>>;
  productId: Scalars['Int'];
};

export type IdInput = {
  id: Scalars['Int'];
};

export type InventoryInput = {
  barcode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type InventoryReceiptQueryInput = {
  id?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  productName?: InputMaybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<User>;
};

export type Lot = {
  __typename?: 'Lot';
  expiresAt: Scalars['DateTime'];
  healthRegistry?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  location?: Maybe<Scalars['String']>;
  manufacturedAt?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  productId: Scalars['Float'];
};

export type LotInput = {
  bestBy: Scalars['DateTime'];
  healthRegistry: Scalars['String'];
  location: Scalars['String'];
  manufacturedAt: Scalars['DateTime'];
  number: Scalars['String'];
  productId: Scalars['Int'];
};

export type LotResponse = {
  __typename?: 'LotResponse';
  errors?: Maybe<Array<FieldError>>;
  inventory?: Maybe<Product>;
  lot?: Maybe<Lot>;
};

export type LotsInput = {
  expiresAt: Scalars['DateTime'];
  healthRegistry?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  location?: InputMaybe<Scalars['String']>;
  lotId?: InputMaybe<Scalars['Int']>;
  manufacturedAt?: InputMaybe<Scalars['DateTime']>;
  productId: Scalars['Int'];
  receiptId: Scalars['Int'];
  stock: Scalars['Float'];
};

export type LotsResponse = {
  __typename?: 'LotsResponse';
  errors?: Maybe<Array<FieldError>>;
  lots?: Maybe<Array<Lot>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changePassword: UserResponse;
  createInventoryReceipt: PhysicalInventoryResponse;
  createLot: LotResponse;
  createPhysicalInventoryDetail: PhysicalInventoryDetailResponse;
  createQuote?: Maybe<Quote>;
  createQuoteDetail: QuoteDetailResponse;
  createReceipt: ReceiptResponse;
  deleteQuoteDetail: QuoteDetailResponse;
  destroyPhysicalInventoryDetail: PhysicalInventoryDetailResponse;
  editLot: LotResponse;
  editQuoteDetail: QuoteDetailResponse;
  login: LoginResponse;
  loginCompany: CompanyResponse;
  logout: Scalars['Boolean'];
  logoutCompany: Scalars['Boolean'];
  register: UserResponseWithPassword;
  registerCompany: CompanyResponse;
  updateInventoryReceipt: PhysicalInventoryResponse;
  updateQuote: QuoteResponse;
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirmation: Scalars['String'];
};


export type MutationCreateInventoryReceiptArgs = {
  input: ProductIdAmountInput;
};


export type MutationCreateLotArgs = {
  input: LotInput;
};


export type MutationCreatePhysicalInventoryDetailArgs = {
  input: CodTransacCodigoLocationInput;
};


export type MutationCreateQuoteArgs = {
  input: CreateQuoteInput;
};


export type MutationCreateQuoteDetailArgs = {
  input: CreateQuoteDetailWithQuoteIdInput;
};


export type MutationCreateReceiptArgs = {
  input: CreateReceiptInput;
};


export type MutationDeleteQuoteDetailArgs = {
  input: DeleteQuoteDetailInput;
};


export type MutationDestroyPhysicalInventoryDetailArgs = {
  input: IdInput;
};


export type MutationEditLotArgs = {
  input: LotInput;
};


export type MutationEditQuoteDetailArgs = {
  input: QuoteDetailInput;
};


export type MutationLoginArgs = {
  options: UsernamePasswordInput;
};


export type MutationLoginCompanyArgs = {
  options: CompanyLoginInput;
};


export type MutationRegisterArgs = {
  options: UsernamePasswordRoleInput;
};


export type MutationRegisterCompanyArgs = {
  options: CompanyRegistrationInput;
};


export type MutationUpdateInventoryReceiptArgs = {
  input: IdCodeAmountLotsInput;
};


export type MutationUpdateQuoteArgs = {
  input: QuoteInput;
};

export type PaginatedCustomers = {
  __typename?: 'PaginatedCustomers';
  currentPage: Scalars['Int'];
  customers: Array<Customer>;
  nextPage?: Maybe<Scalars['Int']>;
};

export type PaginatedInventory = {
  __typename?: 'PaginatedInventory';
  currentPage: Scalars['Int'];
  hasMore?: Maybe<Scalars['Boolean']>;
  nextPage?: Maybe<Scalars['Int']>;
  products: Array<Product>;
};

export type PaginatedInventoryReceipts = {
  __typename?: 'PaginatedInventoryReceipts';
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  receipts: Array<PhysicalInventory>;
};

export type PaginatedQuotes = {
  __typename?: 'PaginatedQuotes';
  hasMore: Scalars['Boolean'];
  quotes: Array<Quote>;
};

export type PaginatedReceipts = {
  __typename?: 'PaginatedReceipts';
  hasMore: Scalars['Boolean'];
  receipts: Array<Receipt>;
};

export type Payment = {
  amount: Scalars['Float'];
  documentId: Scalars['Int'];
  documentType: DebtDocumentType;
};

export type PhysicalInventory = {
  __typename?: 'PhysicalInventory';
  averageCost: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  distribution: Array<PhysicalInventoryDetail>;
  id: Scalars['Int'];
  processedAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  stock: Scalars['Float'];
  systemStock: Scalars['Float'];
  userName: Scalars['String'];
};

export type PhysicalInventoryDetail = {
  __typename?: 'PhysicalInventoryDetail';
  expiresAt: Scalars['DateTime'];
  healthRegistry?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  location?: Maybe<Scalars['String']>;
  lot?: Maybe<Lot>;
  lotId?: Maybe<Scalars['Int']>;
  manufacturedAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  receiptId: Scalars['Int'];
  stock: Scalars['Float'];
};

export type PhysicalInventoryDetailResponse = {
  __typename?: 'PhysicalInventoryDetailResponse';
  errors?: Maybe<Array<FieldError>>;
  inventoryReceipt?: Maybe<PhysicalInventory>;
  inventoryReceiptDetail?: Maybe<PhysicalInventoryDetail>;
};

export type PhysicalInventoryResponse = {
  __typename?: 'PhysicalInventoryResponse';
  errors?: Maybe<Array<FieldError>>;
  physicalInventory?: Maybe<PhysicalInventory>;
};

export type Product = {
  __typename?: 'Product';
  USCost: Scalars['Float'];
  averageCost: Scalars['Float'];
  barcode?: Maybe<Scalars['String']>;
  costAccount?: Maybe<Scalars['String']>;
  costCentre?: Maybe<Scalars['String']>;
  customerId: Scalars['Int'];
  deductible: Scalars['Boolean'];
  details?: Maybe<Scalars['String']>;
  discountAccount?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  groupId: Scalars['Int'];
  id: Scalars['Int'];
  incomeAccount?: Maybe<Scalars['String']>;
  inventoryAccount?: Maybe<Scalars['String']>;
  maximunLevel?: Maybe<Scalars['Int']>;
  minimunLevel?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  ordered: Scalars['Float'];
  points: Scalars['Float'];
  price0: Scalars['Float'];
  price1: Scalars['Float'];
  price2: Scalars['Float'];
  purchaseDiscountAccount?: Maybe<Scalars['String']>;
  purchaseUnit?: Maybe<Scalars['Float']>;
  reference?: Maybe<Scalars['String']>;
  returnAccount?: Maybe<Scalars['String']>;
  salePrice?: Maybe<Scalars['Float']>;
  salesUnit?: Maybe<Scalars['Float']>;
  status: Scalars['Float'];
  stock: Scalars['Float'];
  tax: Scalars['Int'];
  taxAccount?: Maybe<Scalars['String']>;
};

export type ProductIdAmountInput = {
  amount?: InputMaybe<Scalars['Float']>;
  productId: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  customer?: Maybe<Customer>;
  customers: PaginatedCustomers;
  entry?: Maybe<PhysicalInventory>;
  getCustomerTypes: Array<CustomerType>;
  getDebt: Array<Debt>;
  getFirstEntry: PhysicalInventoryResponse;
  getInventoryReceiptByProductID: PhysicalInventoryResponse;
  getLots: LotsResponse;
  getLotsById: LotsResponse;
  getNextEntry: PhysicalInventoryResponse;
  getPriorEntry: PhysicalInventoryResponse;
  inventory: PaginatedInventory;
  lot?: Maybe<Lot>;
  me?: Maybe<User>;
  physicalInventories: PaginatedInventoryReceipts;
  physicalInventory: Array<PhysicalInventory>;
  product?: Maybe<Product>;
  quote?: Maybe<Quote>;
  quoteDetail?: Maybe<QuoteDetail>;
  quotes: PaginatedQuotes;
  receipt?: Maybe<Receipt>;
  receipts: PaginatedReceipts;
  sync?: Maybe<SyncResponse>;
};


export type QueryCustomerArgs = {
  id: Scalars['Int'];
};


export type QueryCustomersArgs = {
  ascending?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<CustomerInput>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryEntryArgs = {
  id: Scalars['Int'];
};


export type QueryGetDebtArgs = {
  customerId: Scalars['Int'];
};


export type QueryGetInventoryReceiptByProductIdArgs = {
  productId: Scalars['Int'];
};


export type QueryGetLotsByIdArgs = {
  id: Scalars['Int'];
  location?: InputMaybe<Scalars['String']>;
};


export type QueryGetNextEntryArgs = {
  input: TransactionIdInput;
};


export type QueryGetPriorEntryArgs = {
  input: TransactionIdInput;
};


export type QueryInventoryArgs = {
  input?: InputMaybe<InventoryInput>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryLotArgs = {
  id: Scalars['Int'];
};


export type QueryPhysicalInventoriesArgs = {
  ascending?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<InventoryReceiptQueryInput>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryProductArgs = {
  id: Scalars['Int'];
};


export type QueryQuoteArgs = {
  id: Scalars['Int'];
};


export type QueryQuoteDetailArgs = {
  numItem: Scalars['Int'];
  quoteId: Scalars['Int'];
};


export type QueryQuotesArgs = {
  ascending?: InputMaybe<Scalars['Boolean']>;
  cursor?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<QuoteQueryInput>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryReceiptArgs = {
  id: Scalars['Int'];
};


export type QueryReceiptsArgs = {
  cursor?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<ReceiptInput>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type Quote = {
  __typename?: 'Quote';
  canceled: Scalars['Boolean'];
  controlNumber?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['Int']>;
  details?: Maybe<Array<QuoteDetail>>;
  discount?: Maybe<Scalars['Float']>;
  finalDate?: Maybe<Scalars['DateTime']>;
  gross?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  net?: Maybe<Scalars['Float']>;
  observations?: Maybe<Scalars['String']>;
  paymentTerms: Scalars['Float'];
  saleType: SaleType;
  sellerId?: Maybe<Scalars['Float']>;
  shippedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<SaleStatus>;
  tax?: Maybe<Scalars['Float']>;
  userName?: Maybe<Scalars['String']>;
};

export type QuoteDetail = {
  __typename?: 'QuoteDetail';
  days?: Maybe<Scalars['Int']>;
  discountPercentage?: Maybe<Scalars['Float']>;
  groupId?: Maybe<Scalars['Int']>;
  observations: Scalars['String'];
  order: Scalars['Int'];
  price: Scalars['Float'];
  product?: Maybe<Product>;
  productId: Scalars['Int'];
  quantity: Scalars['Float'];
  quote?: Maybe<Quote>;
  quoteId: Scalars['Int'];
  taxPercentage: Scalars['Float'];
};

export type QuoteDetailInput = {
  discountPercentage?: InputMaybe<Scalars['Float']>;
  observations?: InputMaybe<Scalars['String']>;
  order: Scalars['Int'];
  price?: InputMaybe<Scalars['Float']>;
  productId: Scalars['Int'];
  quantity?: InputMaybe<Scalars['Int']>;
  quoteId: Scalars['Int'];
  taxPercentage?: InputMaybe<Scalars['Float']>;
};

export type QuoteDetailResponse = {
  __typename?: 'QuoteDetailResponse';
  detail?: Maybe<QuoteDetail>;
  errors?: Maybe<Array<FieldError>>;
};

export type QuoteInput = {
  customerId?: InputMaybe<Scalars['Int']>;
  details?: InputMaybe<Array<QuoteDetailInput>>;
  id: Scalars['Int'];
  observations?: InputMaybe<Scalars['String']>;
  paymentTerms?: InputMaybe<Scalars['Int']>;
  saleType?: InputMaybe<SaleType>;
};

export type QuoteQueryInput = {
  customerId?: InputMaybe<Scalars['Int']>;
  customerName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type QuoteResponse = {
  __typename?: 'QuoteResponse';
  errors?: Maybe<Array<FieldError>>;
  quote?: Maybe<Quote>;
};

export type Receipt = {
  __typename?: 'Receipt';
  card?: Maybe<Scalars['Float']>;
  cash?: Maybe<Scalars['Float']>;
  check?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  credit?: Maybe<Scalars['Float']>;
  customerId: Scalars['Float'];
  date?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  distribution?: Maybe<Array<ReceiptDetail>>;
  id: Scalars['Int'];
  posted: Scalars['Boolean'];
  redepositCheck?: Maybe<Scalars['Float']>;
  return?: Maybe<Scalars['Float']>;
  returnRetained: Scalars['Boolean'];
  sellerId: Scalars['Float'];
  transfer?: Maybe<Scalars['Float']>;
};

export type ReceiptDetail = {
  __typename?: 'ReceiptDetail';
  amount: Scalars['Float'];
  balance: Scalars['Float'];
  documentId: Scalars['Float'];
  documentType?: Maybe<Scalars['String']>;
  order: Scalars['Float'];
  receiptId: Scalars['Float'];
};

export type ReceiptInput = {
  customerId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type ReceiptResponse = {
  __typename?: 'ReceiptResponse';
  errors?: Maybe<Array<FieldError>>;
  receipt?: Maybe<Receipt>;
};

/** Classification of prices that products can be sold at. */
export enum SalePrices {
  Price0 = 'PRICE0',
  Price1 = 'PRICE1',
  Price2 = 'PRICE2',
  SalePrice = 'SALE_PRICE'
}

/** States that a sale, bill, or quote can be. */
export enum SaleStatus {
  Billing = 'BILLING',
  CreditsAndCharges = 'CREDITS_AND_CHARGES',
  Dispatched = 'DISPATCHED',
  Inactive = 'INACTIVE',
  PriceVerification = 'PRICE_VERIFICATION',
  Warehouse = 'WAREHOUSE'
}

/** Types of sales accepted. */
export enum SaleType {
  Co = 'CO',
  Cr = 'CR'
}

export type SyncResponse = {
  __typename?: 'SyncResponse';
  company?: Maybe<Company>;
  user?: Maybe<User>;
};

export type Tenant = {
  __typename?: 'Tenant';
  accessCode: Scalars['String'];
  company: Company;
  companyId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  dbHost: Scalars['String'];
  dbName: Scalars['String'];
  dbUser: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type User = {
  __typename?: 'User';
  cedula: Scalars['String'];
  createdAt: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['Float'];
  isActive: Scalars['Boolean'];
  lastName: Scalars['String'];
  role: UserRoles;
  updatedAt: Scalars['String'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<User>;
};

export type UserResponseWithPassword = {
  __typename?: 'UserResponseWithPassword';
  errors?: Maybe<Array<FieldError>>;
  password?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Roles for every user. */
export enum UserRoles {
  Admin = 'ADMIN',
  PowerUser = 'POWER_USER',
  Seller = 'SELLER',
  User = 'USER'
}

export type UsernamePasswordInput = {
  cedula: Scalars['String'];
  password: Scalars['String'];
};

export type UsernamePasswordRoleInput = {
  cedula: Scalars['String'];
  password: Scalars['String'];
  role: UserRoles;
};

export type TransactionIdInput = {
  transactionId: Scalars['Int'];
};

export type CreateReceiptMutationVariables = Exact<{
  input: CreateReceiptInput;
}>;


export type CreateReceiptMutation = { __typename?: 'Mutation', createReceipt: { __typename?: 'ReceiptResponse', errors?: Array<{ __typename?: 'FieldError', field: string, message: string }> | null, receipt?: { __typename?: 'Receipt', id: number } | null } };

export type CreateQuoteMutationVariables = Exact<{
  input: CreateQuoteInput;
}>;


export type CreateQuoteMutation = { __typename?: 'Mutation', createQuote?: { __typename?: 'Quote', id: number, customerId?: number | null, saleType: SaleType, paymentTerms: number, observations?: string | null, gross?: number | null, discount?: number | null, tax?: number | null, net?: number | null, userName?: string | null, details?: Array<{ __typename?: 'QuoteDetail', order: number, productId: number, quantity: number, price: number, taxPercentage: number, discountPercentage?: number | null, observations: string, quoteId: number, product?: { __typename?: 'Product', name?: string | null } | null }> | null } | null };

export type CreateQuoteDetailInputMutationVariables = Exact<{
  input: CreateQuoteDetailWithQuoteIdInput;
}>;


export type CreateQuoteDetailInputMutation = { __typename?: 'Mutation', createQuoteDetail: { __typename?: 'QuoteDetailResponse', detail?: { __typename?: 'QuoteDetail', quote?: { __typename?: 'Quote', details?: Array<{ __typename?: 'QuoteDetail', order: number, productId: number, quantity: number, price: number, taxPercentage: number, discountPercentage?: number | null, observations: string, quoteId: number, product?: { __typename?: 'Product', id: number, name?: string | null } | null }> | null } | null } | null, errors?: Array<{ __typename?: 'FieldError', field: string, message: string }> | null } };

export type DeleteQuoteDetailMutationVariables = Exact<{
  input: DeleteQuoteDetailInput;
}>;


export type DeleteQuoteDetailMutation = { __typename?: 'Mutation', deleteQuoteDetail: { __typename?: 'QuoteDetailResponse', detail?: { __typename?: 'QuoteDetail', quote?: { __typename?: 'Quote', details?: Array<{ __typename?: 'QuoteDetail', order: number, productId: number, quantity: number, price: number, taxPercentage: number, discountPercentage?: number | null, observations: string, quoteId: number, product?: { __typename?: 'Product', name?: string | null } | null }> | null } | null } | null, errors?: Array<{ __typename?: 'FieldError', field: string, message: string }> | null } };

export type LoginMutationVariables = Exact<{
  cedula: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', accessToken?: string | null, errors?: Array<{ __typename?: 'FieldError', field: string, message: string }> | null, user?: { __typename?: 'User', id: number, cedula: string, firstName: string, lastName: string, role: UserRoles } | null } };

export type LoginCompanyMutationVariables = Exact<{
  rnc: Scalars['String'];
}>;


export type LoginCompanyMutation = { __typename?: 'Mutation', loginCompany: { __typename?: 'CompanyResponse', errors?: Array<{ __typename?: 'FieldError', field: string, message: string }> | null, company?: { __typename?: 'Company', id: string, rnc: string, name: string } | null } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type LogoutCompanyMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutCompanyMutation = { __typename?: 'Mutation', logoutCompany: boolean };

export type UpdateQuoteMutationVariables = Exact<{
  input: QuoteInput;
}>;


export type UpdateQuoteMutation = { __typename?: 'Mutation', updateQuote: { __typename?: 'QuoteResponse', quote?: { __typename?: 'Quote', customerId?: number | null, saleType: SaleType, paymentTerms: number, observations?: string | null, gross?: number | null, discount?: number | null, tax?: number | null, net?: number | null, userName?: string | null, details?: Array<{ __typename?: 'QuoteDetail', order: number, productId: number, quantity: number, price: number, taxPercentage: number, discountPercentage?: number | null, observations: string, quoteId: number, product?: { __typename?: 'Product', id: number, name?: string | null, price0: number, price1: number, price2: number, tax: number, reference?: string | null, USCost: number, averageCost: number, stock: number, barcode?: string | null, groupId: number, expiresAt?: any | null, salePrice?: number | null } | null }> | null, customer?: { __typename?: 'Customer', type?: { __typename?: 'CustomerType', salePrice: SalePrices } | null } | null } | null, errors?: Array<{ __typename?: 'FieldError', field: string, message: string }> | null } };

export type EditQuoteDetailMutationVariables = Exact<{
  editQuoteDetailInput: QuoteDetailInput;
}>;


export type EditQuoteDetailMutation = { __typename?: 'Mutation', editQuoteDetail: { __typename?: 'QuoteDetailResponse', errors?: Array<{ __typename?: 'FieldError', field: string, message: string }> | null, detail?: { __typename?: 'QuoteDetail', productId: number, quoteId: number, quantity: number, price: number, order: number, taxPercentage: number, discountPercentage?: number | null, product?: { __typename?: 'Product', price0: number, price1: number, price2: number, salePrice?: number | null } | null } | null } };

export type GetCustomerDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCustomerDetailQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', id: number, idCard?: string | null, name?: string | null, status?: string | null, debitAccount?: string | null, creditAccount?: string | null, businessName?: string | null, legalRepresentative?: string | null, addressLine1?: string | null, addressLine2?: string | null, sector?: string | null, phone1?: string | null, phone2?: string | null, fax?: string | null, contact?: string | null, restricted: boolean, zone: number, customerTypeId: number, sellerId: number, city?: string | null, details?: string | null, email?: string | null } | null };

export type GetCustomerTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerTypesQuery = { __typename?: 'Query', getCustomerTypes: Array<{ __typename?: 'CustomerType', id: number, name?: string | null, salePrice: SalePrices, fee?: number | null }> };

export type GetCustomersQueryVariables = Exact<{
  input?: InputMaybe<CustomerInput>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  ascending?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetCustomersQuery = { __typename?: 'Query', customers: { __typename?: 'PaginatedCustomers', currentPage: number, nextPage?: number | null, customers: Array<{ __typename?: 'Customer', id: number, name?: string | null, idCard?: string | null, city?: string | null, status?: string | null, paymentTerms?: number | null, customerTypeId: number, balance: number, type?: { __typename?: 'CustomerType', fee?: number | null, id: number, name?: string | null, salePrice: SalePrices } | null }> } };

export type GetDebtQueryVariables = Exact<{
  customerId: Scalars['Int'];
}>;


export type GetDebtQuery = { __typename?: 'Query', getDebt: Array<{ __typename?: 'Debt', advanceTax: number, balance: number, DGIIControl?: string | null, documentId: number, documentType: DebtDocumentType, subtotal: number, net: number, payment: number, tax: number }> };

export type GetDetailQueryVariables = Exact<{
  numItem: Scalars['Int'];
  quoteId: Scalars['Int'];
}>;


export type GetDetailQuery = { __typename?: 'Query', quoteDetail?: { __typename?: 'QuoteDetail', quoteId: number, productId: number, quantity: number, price: number, order: number, taxPercentage: number, discountPercentage?: number | null, observations: string, product?: { __typename?: 'Product', id: number, name?: string | null, details?: string | null, price0: number, price1: number, price2: number, salePrice?: number | null, tax: number } | null } | null };

export type GetInventoryQueryVariables = Exact<{
  input?: InputMaybe<InventoryInput>;
  page?: InputMaybe<Scalars['Int']>;
  limit: Scalars['Int'];
}>;


export type GetInventoryQuery = { __typename?: 'Query', inventory: { __typename?: 'PaginatedInventory', currentPage: number, nextPage?: number | null, hasMore?: boolean | null, products: Array<{ __typename?: 'Product', id: number, name?: string | null, price0: number, price1: number, price2: number, salePrice?: number | null }> } };

export type GetProductDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetProductDetailQuery = { __typename?: 'Query', product?: { __typename?: 'Product', id: number, name?: string | null, price0: number, price1: number, price2: number, salePrice?: number | null, tax: number, reference?: string | null, USCost: number, averageCost: number, stock: number, barcode?: string | null, groupId: number, expiresAt?: any | null } | null };

export type GetQuoteDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetQuoteDetailQuery = { __typename?: 'Query', quote?: { __typename?: 'Quote', customerId?: number | null, id: number, saleType: SaleType, gross?: number | null, status?: SaleStatus | null, createdAt: any, shippedAt?: any | null, finalDate?: any | null, net?: number | null, paymentTerms: number, sellerId?: number | null, observations?: string | null, discount?: number | null, details?: Array<{ __typename?: 'QuoteDetail', quoteId: number, order: number, productId: number, price: number, taxPercentage: number, discountPercentage?: number | null, observations: string, quantity: number, product?: { __typename?: 'Product', id: number, name?: string | null, price0: number, price1: number, price2: number, salePrice?: number | null, tax: number } | null }> | null, customer?: { __typename?: 'Customer', id: number, name?: string | null, customerTypeId: number, paymentTerms?: number | null, email?: string | null, type?: { __typename?: 'CustomerType', fee?: number | null, id: number, name?: string | null, salePrice: SalePrices } | null } | null } | null };

export type GetQuotesQueryVariables = Exact<{
  input?: InputMaybe<QuoteQueryInput>;
  cursor?: InputMaybe<Scalars['Int']>;
  limit: Scalars['Int'];
}>;


export type GetQuotesQuery = { __typename?: 'Query', quotes: { __typename?: 'PaginatedQuotes', hasMore: boolean, quotes: Array<{ __typename?: 'Quote', id: number, saleType: SaleType, gross?: number | null, status?: SaleStatus | null, createdAt: any, shippedAt?: any | null, finalDate?: any | null, net?: number | null, paymentTerms: number, customer?: { __typename?: 'Customer', name?: string | null, id: number } | null }> } };

export type GetReceiptsQueryVariables = Exact<{
  input?: InputMaybe<ReceiptInput>;
  cursor?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetReceiptsQuery = { __typename?: 'Query', receiptPagination: { __typename?: 'PaginatedReceipts', hasMore: boolean, receipts: Array<{ __typename?: 'Receipt', card?: number | null, cash?: number | null, check?: number | null, credit?: number | null, date?: any | null, details?: string | null, discount?: number | null, sellerId: number, transfer?: number | null, id: number, customerId: number, distribution?: Array<{ __typename?: 'ReceiptDetail', balance: number, documentId: number, documentType?: string | null, amount: number, order: number, receiptId: number }> | null }> } };

export type SyncQueryVariables = Exact<{ [key: string]: never; }>;


export type SyncQuery = { __typename?: 'Query', sync?: { __typename?: 'SyncResponse', company?: { __typename?: 'Company', id: string, rnc: string, name: string, config?: { __typename?: 'Config', tax: number, tax1: number } | null } | null, user?: { __typename?: 'User', id: number, cedula: string, firstName: string, lastName: string, role: UserRoles } | null } | null };


export const CreateReceiptDocument = gql`
    mutation CreateReceipt($input: CreateReceiptInput!) {
  createReceipt(input: $input) {
    errors {
      field
      message
    }
    receipt {
      id
    }
  }
}
    `;
export type CreateReceiptMutationFn = Apollo.MutationFunction<CreateReceiptMutation, CreateReceiptMutationVariables>;

/**
 * __useCreateReceiptMutation__
 *
 * To run a mutation, you first call `useCreateReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceiptMutation, { data, loading, error }] = useCreateReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReceiptMutation(baseOptions?: Apollo.MutationHookOptions<CreateReceiptMutation, CreateReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReceiptMutation, CreateReceiptMutationVariables>(CreateReceiptDocument, options);
      }
export type CreateReceiptMutationHookResult = ReturnType<typeof useCreateReceiptMutation>;
export type CreateReceiptMutationResult = Apollo.MutationResult<CreateReceiptMutation>;
export type CreateReceiptMutationOptions = Apollo.BaseMutationOptions<CreateReceiptMutation, CreateReceiptMutationVariables>;
export const CreateQuoteDocument = gql`
    mutation createQuote($input: CreateQuoteInput!) {
  createQuote(input: $input) {
    id
    customerId
    saleType
    paymentTerms
    observations
    gross
    discount
    tax
    net
    userName
    details {
      order
      productId
      quantity
      price
      taxPercentage
      discountPercentage
      observations
      quoteId
      product {
        name
      }
    }
  }
}
    `;
export type CreateQuoteMutationFn = Apollo.MutationFunction<CreateQuoteMutation, CreateQuoteMutationVariables>;

/**
 * __useCreateQuoteMutation__
 *
 * To run a mutation, you first call `useCreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteMutation, { data, loading, error }] = useCreateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteMutation, CreateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteMutation, CreateQuoteMutationVariables>(CreateQuoteDocument, options);
      }
export type CreateQuoteMutationHookResult = ReturnType<typeof useCreateQuoteMutation>;
export type CreateQuoteMutationResult = Apollo.MutationResult<CreateQuoteMutation>;
export type CreateQuoteMutationOptions = Apollo.BaseMutationOptions<CreateQuoteMutation, CreateQuoteMutationVariables>;
export const CreateQuoteDetailInputDocument = gql`
    mutation createQuoteDetailInput($input: CreateQuoteDetailWithQuoteIDInput!) {
  createQuoteDetail(input: $input) {
    detail {
      quote {
        details {
          order
          productId
          quantity
          price
          taxPercentage
          discountPercentage
          observations
          quoteId
          product {
            id
            name
          }
        }
      }
    }
    errors {
      field
      message
    }
  }
}
    `;
export type CreateQuoteDetailInputMutationFn = Apollo.MutationFunction<CreateQuoteDetailInputMutation, CreateQuoteDetailInputMutationVariables>;

/**
 * __useCreateQuoteDetailInputMutation__
 *
 * To run a mutation, you first call `useCreateQuoteDetailInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteDetailInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteDetailInputMutation, { data, loading, error }] = useCreateQuoteDetailInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteDetailInputMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteDetailInputMutation, CreateQuoteDetailInputMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteDetailInputMutation, CreateQuoteDetailInputMutationVariables>(CreateQuoteDetailInputDocument, options);
      }
export type CreateQuoteDetailInputMutationHookResult = ReturnType<typeof useCreateQuoteDetailInputMutation>;
export type CreateQuoteDetailInputMutationResult = Apollo.MutationResult<CreateQuoteDetailInputMutation>;
export type CreateQuoteDetailInputMutationOptions = Apollo.BaseMutationOptions<CreateQuoteDetailInputMutation, CreateQuoteDetailInputMutationVariables>;
export const DeleteQuoteDetailDocument = gql`
    mutation DeleteQuoteDetail($input: DeleteQuoteDetailInput!) {
  deleteQuoteDetail(input: $input) {
    detail {
      quote {
        details {
          order
          productId
          quantity
          price
          taxPercentage
          discountPercentage
          observations
          quoteId
          product {
            name
          }
        }
      }
    }
    errors {
      field
      message
    }
  }
}
    `;
export type DeleteQuoteDetailMutationFn = Apollo.MutationFunction<DeleteQuoteDetailMutation, DeleteQuoteDetailMutationVariables>;

/**
 * __useDeleteQuoteDetailMutation__
 *
 * To run a mutation, you first call `useDeleteQuoteDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuoteDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuoteDetailMutation, { data, loading, error }] = useDeleteQuoteDetailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteQuoteDetailMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuoteDetailMutation, DeleteQuoteDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuoteDetailMutation, DeleteQuoteDetailMutationVariables>(DeleteQuoteDetailDocument, options);
      }
export type DeleteQuoteDetailMutationHookResult = ReturnType<typeof useDeleteQuoteDetailMutation>;
export type DeleteQuoteDetailMutationResult = Apollo.MutationResult<DeleteQuoteDetailMutation>;
export type DeleteQuoteDetailMutationOptions = Apollo.BaseMutationOptions<DeleteQuoteDetailMutation, DeleteQuoteDetailMutationVariables>;
export const LoginDocument = gql`
    mutation Login($cedula: String!, $password: String!) {
  login(options: {cedula: $cedula, password: $password}) {
    errors {
      field
      message
    }
    user {
      id
      cedula
      firstName
      lastName
      role
    }
    accessToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      cedula: // value for 'cedula'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoginCompanyDocument = gql`
    mutation LoginCompany($rnc: String!) {
  loginCompany(options: {accessCode: $rnc}) {
    errors {
      field
      message
    }
    company {
      id
      rnc
      name
    }
  }
}
    `;
export type LoginCompanyMutationFn = Apollo.MutationFunction<LoginCompanyMutation, LoginCompanyMutationVariables>;

/**
 * __useLoginCompanyMutation__
 *
 * To run a mutation, you first call `useLoginCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginCompanyMutation, { data, loading, error }] = useLoginCompanyMutation({
 *   variables: {
 *      rnc: // value for 'rnc'
 *   },
 * });
 */
export function useLoginCompanyMutation(baseOptions?: Apollo.MutationHookOptions<LoginCompanyMutation, LoginCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginCompanyMutation, LoginCompanyMutationVariables>(LoginCompanyDocument, options);
      }
export type LoginCompanyMutationHookResult = ReturnType<typeof useLoginCompanyMutation>;
export type LoginCompanyMutationResult = Apollo.MutationResult<LoginCompanyMutation>;
export type LoginCompanyMutationOptions = Apollo.BaseMutationOptions<LoginCompanyMutation, LoginCompanyMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const LogoutCompanyDocument = gql`
    mutation LogoutCompany {
  logoutCompany
}
    `;
export type LogoutCompanyMutationFn = Apollo.MutationFunction<LogoutCompanyMutation, LogoutCompanyMutationVariables>;

/**
 * __useLogoutCompanyMutation__
 *
 * To run a mutation, you first call `useLogoutCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutCompanyMutation, { data, loading, error }] = useLogoutCompanyMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutCompanyMutation(baseOptions?: Apollo.MutationHookOptions<LogoutCompanyMutation, LogoutCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutCompanyMutation, LogoutCompanyMutationVariables>(LogoutCompanyDocument, options);
      }
export type LogoutCompanyMutationHookResult = ReturnType<typeof useLogoutCompanyMutation>;
export type LogoutCompanyMutationResult = Apollo.MutationResult<LogoutCompanyMutation>;
export type LogoutCompanyMutationOptions = Apollo.BaseMutationOptions<LogoutCompanyMutation, LogoutCompanyMutationVariables>;
export const UpdateQuoteDocument = gql`
    mutation updateQuote($input: QuoteInput!) {
  updateQuote(input: $input) {
    quote {
      customerId
      saleType
      paymentTerms
      observations
      gross
      discount
      tax
      net
      userName
      details {
        order
        productId
        quantity
        price
        taxPercentage
        discountPercentage
        observations
        quoteId
        product {
          id
          name
          price0
          price1
          price2
          tax
          reference
          USCost
          averageCost
          stock
          barcode
          groupId
          expiresAt
          salePrice
        }
      }
      customer {
        type {
          salePrice
        }
      }
    }
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateQuoteMutationFn = Apollo.MutationFunction<UpdateQuoteMutation, UpdateQuoteMutationVariables>;

/**
 * __useUpdateQuoteMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteMutation, { data, loading, error }] = useUpdateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuoteMutation, UpdateQuoteMutationVariables>(UpdateQuoteDocument, options);
      }
export type UpdateQuoteMutationHookResult = ReturnType<typeof useUpdateQuoteMutation>;
export type UpdateQuoteMutationResult = Apollo.MutationResult<UpdateQuoteMutation>;
export type UpdateQuoteMutationOptions = Apollo.BaseMutationOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>;
export const EditQuoteDetailDocument = gql`
    mutation EditQuoteDetail($editQuoteDetailInput: QuoteDetailInput!) {
  editQuoteDetail(input: $editQuoteDetailInput) {
    errors {
      field
      message
    }
    detail {
      productId
      quoteId
      quantity
      price
      order
      taxPercentage
      discountPercentage
      product {
        price0
        price1
        price2
        salePrice
      }
    }
  }
}
    `;
export type EditQuoteDetailMutationFn = Apollo.MutationFunction<EditQuoteDetailMutation, EditQuoteDetailMutationVariables>;

/**
 * __useEditQuoteDetailMutation__
 *
 * To run a mutation, you first call `useEditQuoteDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditQuoteDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editQuoteDetailMutation, { data, loading, error }] = useEditQuoteDetailMutation({
 *   variables: {
 *      editQuoteDetailInput: // value for 'editQuoteDetailInput'
 *   },
 * });
 */
export function useEditQuoteDetailMutation(baseOptions?: Apollo.MutationHookOptions<EditQuoteDetailMutation, EditQuoteDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditQuoteDetailMutation, EditQuoteDetailMutationVariables>(EditQuoteDetailDocument, options);
      }
export type EditQuoteDetailMutationHookResult = ReturnType<typeof useEditQuoteDetailMutation>;
export type EditQuoteDetailMutationResult = Apollo.MutationResult<EditQuoteDetailMutation>;
export type EditQuoteDetailMutationOptions = Apollo.BaseMutationOptions<EditQuoteDetailMutation, EditQuoteDetailMutationVariables>;
export const GetCustomerDetailDocument = gql`
    query GetCustomerDetail($id: Int!) {
  customer(id: $id) {
    id
    idCard
    name
    status
    debitAccount
    creditAccount
    businessName
    legalRepresentative
    addressLine1
    addressLine2
    sector
    phone1
    phone2
    fax
    contact
    restricted
    zone
    customerTypeId
    sellerId
    city
    details
    email
  }
}
    `;

/**
 * __useGetCustomerDetailQuery__
 *
 * To run a query within a React component, call `useGetCustomerDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerDetailQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerDetailQuery, GetCustomerDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerDetailQuery, GetCustomerDetailQueryVariables>(GetCustomerDetailDocument, options);
      }
export function useGetCustomerDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerDetailQuery, GetCustomerDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerDetailQuery, GetCustomerDetailQueryVariables>(GetCustomerDetailDocument, options);
        }
export type GetCustomerDetailQueryHookResult = ReturnType<typeof useGetCustomerDetailQuery>;
export type GetCustomerDetailLazyQueryHookResult = ReturnType<typeof useGetCustomerDetailLazyQuery>;
export type GetCustomerDetailQueryResult = Apollo.QueryResult<GetCustomerDetailQuery, GetCustomerDetailQueryVariables>;
export const GetCustomerTypesDocument = gql`
    query GetCustomerTypes {
  getCustomerTypes {
    id
    name
    salePrice
    fee
  }
}
    `;

/**
 * __useGetCustomerTypesQuery__
 *
 * To run a query within a React component, call `useGetCustomerTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerTypesQuery, GetCustomerTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerTypesQuery, GetCustomerTypesQueryVariables>(GetCustomerTypesDocument, options);
      }
export function useGetCustomerTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerTypesQuery, GetCustomerTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerTypesQuery, GetCustomerTypesQueryVariables>(GetCustomerTypesDocument, options);
        }
export type GetCustomerTypesQueryHookResult = ReturnType<typeof useGetCustomerTypesQuery>;
export type GetCustomerTypesLazyQueryHookResult = ReturnType<typeof useGetCustomerTypesLazyQuery>;
export type GetCustomerTypesQueryResult = Apollo.QueryResult<GetCustomerTypesQuery, GetCustomerTypesQueryVariables>;
export const GetCustomersDocument = gql`
    query GetCustomers($input: CustomerInput, $page: Int, $limit: Int, $ascending: Boolean) {
  customers(input: $input, page: $page, limit: $limit, ascending: $ascending) {
    customers {
      id
      name
      idCard
      city
      status
      paymentTerms
      customerTypeId
      balance
      type {
        fee
        id
        name
        salePrice
      }
    }
    currentPage
    nextPage
  }
}
    `;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      ascending: // value for 'ascending'
 *   },
 * });
 */
export function useGetCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
      }
export function useGetCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
        }
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
export const GetDebtDocument = gql`
    query GetDebt($customerId: Int!) {
  getDebt(customerId: $customerId) {
    advanceTax
    balance
    DGIIControl
    documentId
    documentType
    subtotal
    net
    payment
    tax
  }
}
    `;

/**
 * __useGetDebtQuery__
 *
 * To run a query within a React component, call `useGetDebtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebtQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetDebtQuery(baseOptions: Apollo.QueryHookOptions<GetDebtQuery, GetDebtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDebtQuery, GetDebtQueryVariables>(GetDebtDocument, options);
      }
export function useGetDebtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDebtQuery, GetDebtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDebtQuery, GetDebtQueryVariables>(GetDebtDocument, options);
        }
export type GetDebtQueryHookResult = ReturnType<typeof useGetDebtQuery>;
export type GetDebtLazyQueryHookResult = ReturnType<typeof useGetDebtLazyQuery>;
export type GetDebtQueryResult = Apollo.QueryResult<GetDebtQuery, GetDebtQueryVariables>;
export const GetDetailDocument = gql`
    query GetDetail($numItem: Int!, $quoteId: Int!) {
  quoteDetail(numItem: $numItem, quoteId: $quoteId) {
    quoteId
    productId
    quantity
    price
    order
    taxPercentage
    discountPercentage
    observations
    product {
      id
      name
      details
      price0
      price1
      price2
      salePrice
      tax
    }
  }
}
    `;

/**
 * __useGetDetailQuery__
 *
 * To run a query within a React component, call `useGetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailQuery({
 *   variables: {
 *      numItem: // value for 'numItem'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useGetDetailQuery(baseOptions: Apollo.QueryHookOptions<GetDetailQuery, GetDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDetailQuery, GetDetailQueryVariables>(GetDetailDocument, options);
      }
export function useGetDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDetailQuery, GetDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDetailQuery, GetDetailQueryVariables>(GetDetailDocument, options);
        }
export type GetDetailQueryHookResult = ReturnType<typeof useGetDetailQuery>;
export type GetDetailLazyQueryHookResult = ReturnType<typeof useGetDetailLazyQuery>;
export type GetDetailQueryResult = Apollo.QueryResult<GetDetailQuery, GetDetailQueryVariables>;
export const GetInventoryDocument = gql`
    query GetInventory($input: InventoryInput, $page: Int, $limit: Int!) {
  inventory(input: $input, page: $page, limit: $limit) {
    currentPage
    nextPage
    products {
      id
      name
      price0
      price1
      price2
      salePrice
    }
    hasMore
  }
}
    `;

/**
 * __useGetInventoryQuery__
 *
 * To run a query within a React component, call `useGetInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetInventoryQuery(baseOptions: Apollo.QueryHookOptions<GetInventoryQuery, GetInventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInventoryQuery, GetInventoryQueryVariables>(GetInventoryDocument, options);
      }
export function useGetInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInventoryQuery, GetInventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInventoryQuery, GetInventoryQueryVariables>(GetInventoryDocument, options);
        }
export type GetInventoryQueryHookResult = ReturnType<typeof useGetInventoryQuery>;
export type GetInventoryLazyQueryHookResult = ReturnType<typeof useGetInventoryLazyQuery>;
export type GetInventoryQueryResult = Apollo.QueryResult<GetInventoryQuery, GetInventoryQueryVariables>;
export const GetProductDetailDocument = gql`
    query GetProductDetail($id: Int!) {
  product(id: $id) {
    id
    name
    price0
    price1
    price2
    salePrice
    tax
    reference
    USCost
    averageCost
    stock
    barcode
    groupId
    expiresAt
  }
}
    `;

/**
 * __useGetProductDetailQuery__
 *
 * To run a query within a React component, call `useGetProductDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductDetailQuery(baseOptions: Apollo.QueryHookOptions<GetProductDetailQuery, GetProductDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductDetailQuery, GetProductDetailQueryVariables>(GetProductDetailDocument, options);
      }
export function useGetProductDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductDetailQuery, GetProductDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductDetailQuery, GetProductDetailQueryVariables>(GetProductDetailDocument, options);
        }
export type GetProductDetailQueryHookResult = ReturnType<typeof useGetProductDetailQuery>;
export type GetProductDetailLazyQueryHookResult = ReturnType<typeof useGetProductDetailLazyQuery>;
export type GetProductDetailQueryResult = Apollo.QueryResult<GetProductDetailQuery, GetProductDetailQueryVariables>;
export const GetQuoteDetailDocument = gql`
    query GetQuoteDetail($id: Int!) {
  quote(id: $id) {
    customerId
    id
    saleType
    gross
    status
    createdAt
    shippedAt
    finalDate
    net
    paymentTerms
    sellerId
    observations
    discount
    details {
      quoteId
      order
      productId
      price
      taxPercentage
      discountPercentage
      observations
      quantity
      product {
        id
        name
        price0
        price1
        price2
        salePrice
        tax
      }
    }
    customer {
      id
      name
      customerTypeId
      paymentTerms
      email
      name
      id
      type {
        fee
        id
        name
        salePrice
      }
    }
  }
}
    `;

/**
 * __useGetQuoteDetailQuery__
 *
 * To run a query within a React component, call `useGetQuoteDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuoteDetailQuery(baseOptions: Apollo.QueryHookOptions<GetQuoteDetailQuery, GetQuoteDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuoteDetailQuery, GetQuoteDetailQueryVariables>(GetQuoteDetailDocument, options);
      }
export function useGetQuoteDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteDetailQuery, GetQuoteDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuoteDetailQuery, GetQuoteDetailQueryVariables>(GetQuoteDetailDocument, options);
        }
export type GetQuoteDetailQueryHookResult = ReturnType<typeof useGetQuoteDetailQuery>;
export type GetQuoteDetailLazyQueryHookResult = ReturnType<typeof useGetQuoteDetailLazyQuery>;
export type GetQuoteDetailQueryResult = Apollo.QueryResult<GetQuoteDetailQuery, GetQuoteDetailQueryVariables>;
export const GetQuotesDocument = gql`
    query GetQuotes($input: QuoteQueryInput, $cursor: Int, $limit: Int!) {
  quotes(input: $input, cursor: $cursor, limit: $limit) {
    quotes {
      id
      saleType
      gross
      status
      createdAt
      shippedAt
      finalDate
      net
      paymentTerms
      customer {
        name
        id
      }
    }
    hasMore
  }
}
    `;

/**
 * __useGetQuotesQuery__
 *
 * To run a query within a React component, call `useGetQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetQuotesQuery(baseOptions: Apollo.QueryHookOptions<GetQuotesQuery, GetQuotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuotesQuery, GetQuotesQueryVariables>(GetQuotesDocument, options);
      }
export function useGetQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuotesQuery, GetQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuotesQuery, GetQuotesQueryVariables>(GetQuotesDocument, options);
        }
export type GetQuotesQueryHookResult = ReturnType<typeof useGetQuotesQuery>;
export type GetQuotesLazyQueryHookResult = ReturnType<typeof useGetQuotesLazyQuery>;
export type GetQuotesQueryResult = Apollo.QueryResult<GetQuotesQuery, GetQuotesQueryVariables>;
export const GetReceiptsDocument = gql`
    query GetReceipts($input: ReceiptInput, $cursor: Int, $limit: Int) {
  receiptPagination: receipts(input: $input, cursor: $cursor, limit: $limit) {
    hasMore
    receipts {
      card
      cash
      check
      credit
      date
      details
      discount
      sellerId
      transfer
      id
      distribution {
        balance
        documentId
        documentType
        amount
        order
        receiptId
      }
      customerId
    }
  }
}
    `;

/**
 * __useGetReceiptsQuery__
 *
 * To run a query within a React component, call `useGetReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiptsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetReceiptsQuery(baseOptions?: Apollo.QueryHookOptions<GetReceiptsQuery, GetReceiptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceiptsQuery, GetReceiptsQueryVariables>(GetReceiptsDocument, options);
      }
export function useGetReceiptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceiptsQuery, GetReceiptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceiptsQuery, GetReceiptsQueryVariables>(GetReceiptsDocument, options);
        }
export type GetReceiptsQueryHookResult = ReturnType<typeof useGetReceiptsQuery>;
export type GetReceiptsLazyQueryHookResult = ReturnType<typeof useGetReceiptsLazyQuery>;
export type GetReceiptsQueryResult = Apollo.QueryResult<GetReceiptsQuery, GetReceiptsQueryVariables>;
export const SyncDocument = gql`
    query Sync {
  sync {
    company {
      id
      rnc
      name
      config {
        tax
        tax1
      }
    }
    user {
      id
      cedula
      firstName
      lastName
      role
    }
  }
}
    `;

/**
 * __useSyncQuery__
 *
 * To run a query within a React component, call `useSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncQuery({
 *   variables: {
 *   },
 * });
 */
export function useSyncQuery(baseOptions?: Apollo.QueryHookOptions<SyncQuery, SyncQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SyncQuery, SyncQueryVariables>(SyncDocument, options);
      }
export function useSyncLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SyncQuery, SyncQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SyncQuery, SyncQueryVariables>(SyncDocument, options);
        }
export type SyncQueryHookResult = ReturnType<typeof useSyncQuery>;
export type SyncLazyQueryHookResult = ReturnType<typeof useSyncLazyQuery>;
export type SyncQueryResult = Apollo.QueryResult<SyncQuery, SyncQueryVariables>;