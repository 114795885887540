import React, { useState } from "react";
import { Customer } from "../lib/GraphQL/generated/types";

type CustomerProviderProps = { children: React.ReactNode };

type CustomerQuestion =
  | Pick<Customer, "id" | "name" | "paymentTerms" | "customerTypeId" | "type">
  | undefined;

export type CustomerContextType = {
  customer?: CustomerQuestion;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerQuestion>>;
  handleSignOut: () => void;
};

export const CustomerContext = React.createContext<
  CustomerContextType | undefined
>(undefined);

const ClientProvider = ({ children }: CustomerProviderProps) => {
  const [customer, setCustomer] = useState<CustomerQuestion>(undefined);

  const handleSignOut = async () => {
    setCustomer(undefined);
  };

  const value: CustomerContextType = {
    customer: customer,
    setCustomer: setCustomer,
    handleSignOut,
  };

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
};

export default ClientProvider;
