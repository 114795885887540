import {
  IonButton,
  IonCard,
  IonGrid,
  IonIcon,
  IonInput,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { search } from "ionicons/icons";
import { useState } from "react";
import { SelectClientType } from "../../../pages/Customer";
import ClientSearch from "../../../pages/Customer/search";
import { VerticalDivider } from "../VerticalDivider";
import "./style.scss";

type FiltersValuesType = {
  value: string;
  label: string;
};

type SearchBarProps = {
  /**
   ** value and its change handler to utilize its input
   */
  valueHandleChange: (text: string) => void;

  /**
   ** placeholder for the input box
   */
  placeholder: string;

  /**
   ** everything related to the input button
   */
  button?: boolean;
  buttonFunction?: () => void;
  buttonIcon?: string;
  buttonLabel?: string;
  /**
   ** everything related to the input button
   */
  filtersEnabled?: boolean;
  filtersOptions?: FiltersValuesType[];
  filterValue?: string;
  filterHandleChange?: (value: string) => void;
  value?: string;
};

export const SearchBar = ({
  valueHandleChange,
  placeholder,
  button = false,
  buttonFunction,
  buttonLabel,
  buttonIcon,
  filtersEnabled = false,
  filtersOptions,
  filterValue,
  filterHandleChange,
  value,
}: SearchBarProps) => {
  const [openSearchClient, setOpenSearchClient] = useState<boolean>(false);
  const [customer, setCustomer] = useState<number>();

  const handleCloseClient = async (client: SelectClientType | undefined) => {
    if (client) {
      valueHandleChange(
        customer && filterValue === "idCliente"
          ? client.id.toString()
          : client.name!.trim()
      );
    }
    setOpenSearchClient(false);
  };

  return (
    <IonCard className="search-card">
      <IonGrid className="search-grid">
        <IonRow>
          <IonInput
            className="search-input"
            placeholder={placeholder}
            value={value}
            style={{ textTransform: "uppercase" }}
            onIonInput={(e) =>
              valueHandleChange
                ? valueHandleChange(e.target.value! as string)
                : null
            }
          />
          {button ? (
            <>
              <VerticalDivider margin="10px" />
              <IonButton
                className="right-button"
                size="large"
                fill="clear"
                expand="full"
                onClick={buttonFunction}
              >
                {buttonLabel}
                {buttonIcon ? <IonIcon icon={buttonIcon} /> : null}
              </IonButton>
            </>
          ) : null}
          {filterValue ? (
            <>
              {filterValue === "idCliente" ||
              filterValue === "nombreCliente" ? (
                <IonButton
                  onClick={() => setOpenSearchClient(true)}
                  style={{ alignText: "center", padding: "5px" }}
                >
                  <IonIcon icon={search} />
                </IonButton>
              ) : null}
              <VerticalDivider margin="10px" />
              <IonSelect
                style={{ marginRight: "10px" }}
                value={filterValue}
                placeholder="Seleccione un filtro"
                onIonChange={(e) =>
                  filterHandleChange ? filterHandleChange(e.detail.value) : null
                }
              >
                {filtersOptions?.map((option, key) => {
                  return (
                    <IonSelectOption key={key} value={option.value}>
                      {option.label}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </>
          ) : null}
        </IonRow>
      </IonGrid>
      <IonModal isOpen={openSearchClient}>
        <ClientSearch
          handleClientSelect={setCustomer}
          handleClose={handleCloseClient}
        />
      </IonModal>
    </IonCard>
  );
};
