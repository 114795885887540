import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonCard,
  useIonLoading,
  IonToolbar,
  useIonViewWillEnter,
  IonBackButton,
  IonGrid,
  IonRow,
  IonCol,
  useIonAlert,
  IonLoading,
} from "@ionic/react";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { DetailButtons } from "../../components/Common/DetailButtons";
import ErrorAlert from "../../components/Common/ErrorAlert";
import { RECEIPT_ROUTE } from "../../constants/routes";
import useCustomer from "../../hooks/useCustomer";
import {
  GetCustomerDetailQuery,
  useGetCustomerDetailLazyQuery,
} from "../../lib/GraphQL/generated/types";
import { downloadFilePdf } from "../../utils/fileManagement";

import "./style.scss";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";

type SelectClientType = GetCustomerDetailQuery["customer"];

type CustomerParams = { customerId: string };

const CustomerDetail = () => {
  let { customerId } = useParams<CustomerParams>();
  const customerContext = useCustomer();
  const history = useHistory();
  const [customerInfo, setCustomerInfo] = useState<
    SelectClientType | null | undefined
  >();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [present, dismiss] = useIonLoading();
  const [openAlert] = useIonAlert();

  const [getCustomer, { error, loading }] = useGetCustomerDetailLazyQuery({
    variables: { id: parseInt(customerId) },
  });

  const handleSetClient = async () => {
    if (customerInfo) {
      await customerContext.setCustomer({
        id: customerInfo.id,
        name: customerInfo.name,
        customerTypeId: customerInfo.customerTypeId,
      });
    }
  };
  useIonViewWillEnter(async () => {
    try {
      const results = await getCustomer();
      if (error) {
        setErrorMessage(error.message);
        setShowAlert(true);
      } else {
        if (results.data) {
          setCustomerInfo(results.data.customer);
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        await setErrorMessage(e.message);
      }
      setShowAlert(true);
    }
  });

  const genStatus = async () => {
    present({
      message: "Descargando...",
    });
    try {
      await downloadFilePdf({
        fileName: `client-${customerId}.pdf`,
        uri: `customer-status?clientFrom=${customerId}&clientTo=${customerId}&dateFrom=${new Date(
          "2000-01-01"
        )}&dateTo=${new Date().toString()}`,
      });
    } catch (e) {
      openAlert({
        cssClass: "my-css",
        header: "Alert",
        message: String(e as Error),
        buttons: [
          "Cancel",
          { text: "Ok", handler: (d) => console.log("ok pressed") },
        ],
        onDidDismiss: (e) => console.log("did dismiss"),
      });
    }
    dismiss();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Cliente #{customerInfo?.id}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonCard>
          <DetailButtons
            middle="Estado de cuenta"
            middleFunction={genStatus}
            mailFunction={() => {
              handleSetClient().then(() => {
                history.push(RECEIPT_ROUTE);
              });
            }}
            mailText="Recibos"
            omit={{ edit: true }}
          />
          <IonGrid className="detail-grid">
            <IonRow className="first-row">
              <IonCol className="col-left">Codigo: #{customerId}</IonCol>
              <IonCol className="col-right">
                Cliente: {customerInfo?.name}
              </IonCol>
            </IonRow>
            {customerInfo?.email && (
              <IonRow>
                <IonCol className="col-left">
                  Correo Electrónico: {customerInfo.email.toLowerCase()}
                </IonCol>
              </IonRow>
            )}
            {customerInfo?.phone1 && (
              <IonRow>
                <IonCol className="col-left">
                  Teléfono{customerInfo?.phone2 ? " 1" : ""}:{" "}
                  {formatPhoneNumber(customerInfo.phone1)}
                </IonCol>
              </IonRow>
            )}
            {customerInfo?.phone2 && (
              <IonRow>
                <IonCol className="col-left">
                  Teléfono 2: {formatPhoneNumber(customerInfo.phone2)}
                </IonCol>
              </IonRow>
            )}
            {customerInfo?.contact && (
              <IonRow>
                <IonCol className="col-left">
                  Contacto: {customerInfo.contact}
                </IonCol>
              </IonRow>
            )}
            {customerInfo?.zone && (
              <IonRow>
                <IonCol className="col-left">
                  Zona: #{customerInfo?.zone}
                </IonCol>
              </IonRow>
            )}
            {customerInfo?.city && (
              <IonRow>
                <IonCol className="col-left">
                  Ciudad: {customerInfo.city}
                </IonCol>
              </IonRow>
            )}
            {customerInfo?.sector && (
              <IonRow>
                <IonCol className="col-left">
                  Sector: {customerInfo.sector}
                </IonCol>
              </IonRow>
            )}
            {customerInfo?.addressLine1 && (
              <IonRow>
                <IonCol className="col-left">
                  Dirección:{" "}
                  {`${customerInfo.addressLine1} ${customerInfo.addressLine2}`}
                </IonCol>
              </IonRow>
            )}
            <IonRow>
              <IonCol className="col-left">
                Tipo de Cliente: {customerContext.customer?.type?.name || ""}
              </IonCol>
            </IonRow>
            {customerInfo?.details && (
              <IonRow>
                <IonCol className="col-left">
                  Observaciones: {customerInfo.details}
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </IonCard>
      </IonContent>
      <IonLoading isOpen={loading} message={"Cargando..."} />
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
    </IonPage>
  );
};

export default CustomerDetail;
