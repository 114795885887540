import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { newspaper, pencil, print } from "ionicons/icons";

import "./style.scss";

type DetailButtonsType = {
  /**
   ** option to omit buttons if needed
   */
  omit?: { edit?: boolean; middle?: boolean; mail?: boolean };

  editFunction?: () => void;
  editText?: string;

  /**
   ** variable button
   */
  middle?: string;
  middleFunction?: () => void;

  /**
   ** send mail functionalities
   */
  mailText?:string
  mailFunction?: ()=>void;
};

export const DetailButtons = ({
  omit,
  editText="Editar",
  editFunction,
  middle,
  middleFunction,
  mailText="Correo",
  mailFunction,
}: DetailButtonsType) => {
  return (
    <IonGrid>
      <IonRow>
        {omit?.edit ? null : (
          <IonCol className="col-center">
            <IonButton onClick={editFunction}>
              <IonIcon icon={pencil} className="icon" />
              {editText}
            </IonButton>
          </IonCol>
        )}
        {omit?.middle ? null : (
          <IonCol className="col-center">
            <IonButton onClick={middleFunction}>
              <IonIcon icon={newspaper} className="icon" />
              {middle}
            </IonButton>
          </IonCol>
        )}
        {omit?.mail ? null : (
          <IonCol className="col-center">
            <IonButton onClick={mailFunction}>
              <IonIcon icon={print} className="icon" />
              {mailText}
            </IonButton>
          </IonCol>
        )}
      </IonRow>
    </IonGrid>
  );
};
