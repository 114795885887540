import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonCard,
  IonLabel,
  IonGrid,
  IonRow,
  useIonViewWillEnter,
  IonFab,
  IonIcon,
  IonCardTitle,
  IonCardHeader,
  IonCol,
  IonBackButton,
  IonItem,
  IonInput,
  InputChangeEventDetail,
  IonButton,
  IonText,
  IonLoading,
  IonTextarea,
} from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ErrorAlert from "../../../components/Common/ErrorAlert";
import { SearchBar } from "../../../components/Common/InputBar";
import { CUSTOMER_ROUTE, RECEIPT_ROUTE } from "../../../constants/routes";
import useCustomer from "../../../hooks/useCustomer";
import {
  CreateReceiptInput,
  Debt,
  Payment,
  useCreateReceiptMutation,
  useGetDebtLazyQuery,
} from "../../../lib/GraphQL/generated/types";
import "./style.scss";
import { formatToCurrency } from "../../../utils/formatToCurrency";

type PaymentTypes =
  | "card"
  | "cash"
  | "credit"
  | "check"
  | "transfer"
  | "discount";

type InputType = {
  label: string;
  placeholder: string;
  type: PaymentTypes;
  handler: (
    event: CustomEvent<InputChangeEventDetail>,
    value: PaymentTypes
  ) => void;
};

const NumberInputField = ({ label, placeholder, type, handler }: InputType) => {
  return (
    <>
      <IonLabel style={{ fontWeight: "bold" }}>{label}: $</IonLabel>
      <IonInput
        type="number"
        placeholder={placeholder}
        onIonChange={(e) => handler(e, type)}
      />
    </>
  );
};

const DebtsPage = () => {
  const customerContext = useCustomer();
  const [filterText, setFilterText] = useState<string>("");
  const [debts, setDebts] = useState<Debt[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [balance, setBalance] = useState<number>(0);
  const [selectedDebts, setSelectedDebts] = useState<Debt[]>([]);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [resto, setResto] = useState<number>(0);
  const [observations, setObservations] = useState<string>("");
  const [receiptInput, setReceiptInput] = useState<CreateReceiptInput>({
    customerId: customerContext.customer?.id || 0,
    payment: [],
    card: 0,
    cash: 0,
    credit: 0,
    check: 0,
    transfer: 0,
    returnRetained: false,
    return: 0,
    discount: 0,
    details: "",
  });

  const history = useHistory();

  const [createReceipt, { error, loading: submitLoading }] =
    useCreateReceiptMutation();

  const [getDebt, { loading }] = useGetDebtLazyQuery({
    fetchPolicy: "no-cache",
    variables: { customerId: customerContext.customer?.id! },
  });

  const handleGetBalance = () => {
    const value1 = receiptInput.cash || 0;
    const value2 = receiptInput.credit || 0;
    const value3 = receiptInput.check || 0;
    const value4 = receiptInput.transfer || 0;
    const value5 = receiptInput.card || 0;
    const value6 = receiptInput.discount || 0;
    const total = value1 + value2 + value3 + value4 + value5 + value6;
    return total;
  };

  const handleGetRest = () => {
    setResto(
      balance -
        selectedDebts.reduce((acc, debt) => {
          return acc + debt.balance;
        }, 0)
    );
  };

  const saveReceipt = async () => {
    if (debts.length === 0) {
      setErrorMessage("No hay deudas para pagar");
      setShowAlert(true);
      return;
    }

    if (selectedDebts.length === 0) {
      setErrorMessage("No hay deudas seleccionadas");
      setShowAlert(true);
      return;
    }

    if (balance <= 0) {
      setErrorMessage("El balance no puede ser menor o igual a 0");
      setShowAlert(true);
      return;
    }

    if (balance > 0 && debts.length > 0 && selectedDebts.length > 0) {
      try {
        const receiptMade = await createReceipt({
          variables: {
            input: {
              ...receiptInput,
              payment: payments,
              details: observations,
              discount: receiptInput.discount,
            },
          },
        });
        if (error) {
          setErrorMessage(error.message);
          setShowAlert(true);
        } else {
          history.replace(
            `${RECEIPT_ROUTE}/detail/${receiptMade.data?.createReceipt.receipt?.id}`
          );
        }
      } catch (e) {
        if (e instanceof Error) {
          await setErrorMessage(e.message);
        }
        setShowAlert(true);
      }
    }
  };

  const pushData = (newData: Debt[]) => {
    setDebts([...debts, ...newData]);
  };

  useIonViewWillEnter(async () => {
    try {
      const newResults = await getDebt();
      if (error) {
        setErrorMessage(error.message);
        setShowAlert(true);
      } else {
        if (newResults.data) {
          pushData(newResults.data.getDebt);
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        await setErrorMessage(e.message);
      }
      setShowAlert(true);
    }
  });

  const filterTextHandler = async (text: string) => {
    setFilterText(text);
  };

  const sortDebtsSeletedFirst = () => {
    const sortedDebts = selectedDebts.concat(
      debts.filter((d) => !selectedDebts.includes(d))
    );
    return sortedDebts;
  };

  const assignPayments = () => {
    let tempBalance = balance;
    const newPayment = selectedDebts.map((d) => {
      const applied =
        tempBalance >= d.balance
          ? d.balance
          : tempBalance <= 0
          ? 0
          : tempBalance;
      const pay = {
        documentId: d.documentId,
        amount: applied,
        documentType: d.documentType,
      };
      tempBalance -= d.balance;
      return pay;
    });
    setPayments(newPayment);
    setReceiptInput({ ...receiptInput, payment: newPayment });
  };

  const handleSelectDebt = (debt: Debt) => {
    if (selectedDebts.includes(debt)) {
      setSelectedDebts(
        selectedDebts.filter((d) => d.documentId !== debt.documentId)
      );
    } else {
      setSelectedDebts([...selectedDebts, debt]);
    }
  };

  const handleChangePaymentInput = (
    event: CustomEvent<InputChangeEventDetail>,
    value: PaymentTypes
  ) => {
    setReceiptInput({
      ...receiptInput,
      [value]: Number(parseFloat(event.detail.value || "0")),
    });
  };

  useEffect(() => {
    assignPayments();
    handleGetRest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance, selectedDebts]);

  useEffect(() => {
    setBalance(handleGetBalance());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptInput]);

  if (customerContext.customer === undefined) {
    history.replace(CUSTOMER_ROUTE);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Crear Recibo</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="light">
        <div className="form">
          <div>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>
                  Cliente: {customerContext.customer?.name}
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </div>
          <div>
            <IonItem>
              <NumberInputField
                label="Tarjeta"
                placeholder="Monto de Tarjeta"
                type="card"
                handler={handleChangePaymentInput}
              />
            </IonItem>
            <IonItem>
              <NumberInputField
                label="Transferencia"
                placeholder="Monto de Transferencia"
                type="transfer"
                handler={handleChangePaymentInput}
              />
            </IonItem>
            <IonItem>
              <NumberInputField
                label="Cheque"
                placeholder="Monto de Cheque"
                type="check"
                handler={handleChangePaymentInput}
              />
            </IonItem>
            <IonItem>
              <NumberInputField
                label="Efectivo"
                placeholder="Monto de Efectivo"
                type="cash"
                handler={handleChangePaymentInput}
              />
            </IonItem>
            <IonItem>
              <NumberInputField
                label="Crédito"
                placeholder="Monto de Crédito"
                type="credit"
                handler={handleChangePaymentInput}
              />
            </IonItem>
            <IonItem>
              <NumberInputField
                label="Descuento"
                placeholder="Monto a descontar"
                type="discount"
                handler={handleChangePaymentInput}
              />
            </IonItem>
            <IonItem>
              <IonCol>
                <IonLabel style={{ fontWeight: "bold" }}>
                  Balance: {formatToCurrency(balance)}
                </IonLabel>
              </IonCol>
              <IonCol>
                <IonLabel style={{ fontWeight: "bold" }}>
                  {resto > 0 ? "Abono:" : "Faltan:"}
                  {formatToCurrency(Math.abs(resto))}
                </IonLabel>
              </IonCol>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Observaciones</IonLabel>
              <IonTextarea
                value={observations.toUpperCase()}
                maxlength={50}
                onIonChange={(e) => setObservations(e.detail.value!)}
              />
            </IonItem>
          </div>
          <div className="search-fixed">
            <SearchBar
              placeholder="Filtrar Deudas"
              valueHandleChange={filterTextHandler}
            />
          </div>
          <div>
            <IonGrid>
              <IonRow style={{ paddingLeft: "40px" }}>
                <IonCol>Id</IonCol>
                <IonCol>Tipo</IonCol>
                <IonCol>Monto</IonCol>
                <IonCol>Aplicado</IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
        <div>
          {debts.length > 0 ? (
            <IonList className="list create-list">
              {sortDebtsSeletedFirst().map((debt) => {
                if (debt.documentId.toString().includes(filterText)) {
                  return (
                    <IonCard
                      onClick={() => handleSelectDebt(debt)}
                      key={debt.documentId}
                    >
                      <IonGrid>
                        <IonRow>
                          {selectedDebts.includes(debt) ? (
                            <IonIcon
                              icon={checkmarkCircle}
                              color="primary"
                              style={{ height: "2rem", width: "2rem" }}
                            />
                          ) : (
                            <IonIcon
                              style={{ height: "2rem", width: "2rem" }}
                            />
                          )}
                          <IonCol>
                            <IonLabel>
                              <h2>{debt.documentId}</h2>
                            </IonLabel>
                          </IonCol>
                          <IonCol>
                            <IonLabel>
                              <h2>{debt.documentType}</h2>
                            </IonLabel>
                          </IonCol>
                          <IonCol>
                            <IonLabel>
                              <h3>{formatToCurrency(debt.balance)}</h3>
                            </IonLabel>
                          </IonCol>
                          {selectedDebts.includes(debt) ? (
                            <IonCol>
                              <IonLabel>
                                <h3>
                                  {
                                    payments.find(
                                      (p) => p.documentId === debt.documentId
                                    )?.amount
                                  }
                                </h3>
                              </IonLabel>
                            </IonCol>
                          ) : (
                            <IonCol></IonCol>
                          )}
                        </IonRow>
                      </IonGrid>
                    </IonCard>
                  );
                }
                return null;
              })}
            </IonList>
          ) : (
            <div style={{ textAlign: "center" }}>
              <IonText
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                No hay deudas
              </IonText>
            </div>
          )}
        </div>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonButton shape="round" onClick={saveReceipt}>
            Aplicar Pago
          </IonButton>
        </IonFab>
      </IonContent>
      <IonLoading isOpen={loading || submitLoading} message={"Cargando..."} />
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
    </IonPage>
  );
};

export default DebtsPage;
