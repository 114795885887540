import React from "react";

import { CompanyContext, CompanyContextType } from "../context/CompanyContext";

const useCompany = (): CompanyContextType => {
  const context = React.useContext(CompanyContext);

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
};

export default useCompany;
