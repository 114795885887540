import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { checkAccessCode, checkToken, setToken } from "../../data/storage";
import { Http } from "@capacitor-community/http";
import HttpStatus from "http-status-codes";
import { apiUri } from "../../constants/apiUri";
import { onError } from "@apollo/client/link/error";
import { triggerTokenExpired } from "../../router/AppRouter";

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        // Apollo Server sets code to NOT_AUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        case "NOT_AUTHENTICATED": {
          setToken("");
          triggerTokenExpired();
          return console.log("NOT_AUTHENTICATED");
        }
        default: {
          return console.log(err.message);
        }
      }
    }
  }
});

export const capacitorHttpFetchImpl = async (url: string, options: any) => {
  let http = Http;

  options.data = JSON.parse(options.body);
  options.method = options.method.toLowerCase();

  let response: any = await http.request({ url, ...options });

  const responseBody =
    typeof response.data === `object`
      ? JSON.stringify(response.data)
      : response.data;
  const headers = new Headers();
  Object.entries(response.headers).forEach(function ([key, value]) {
    headers.append(key, value as string);
  });

  return new Response(responseBody, {
    status: response.status,
    statusText: HttpStatus.getStatusText(response.status),
    headers,
  });
};

const httpLink = createHttpLink({
  // uri: "https://www.api.tecno-logica.org/api",
  uri: `${apiUri}/sic`,
  fetch: capacitorHttpFetchImpl,
});

const setAuthorizationLink = setContext(async (request, previousContext) => {
  const token = await checkToken();
  const accessCode = await checkAccessCode();
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      "X-Company-Access-Code": accessCode ? accessCode : "",
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([setAuthorizationLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  credentials: "include",
});
