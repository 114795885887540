import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonCard,
  IonLabel,
  IonGrid,
  IonRow,
  useIonViewWillEnter,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
} from "@ionic/react";
import _ from "lodash";
import { createRef, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ErrorAlert from "../../components/Common/ErrorAlert";
import { SearchBar } from "../../components/Common/InputBar";
import useCustomer from "../../hooks/useCustomer";
import { useGetCustomersLazyQuery } from "../../lib/GraphQL/generated/types";

import "./style.scss";
import { formatToCurrency } from "../../utils/formatToCurrency";
import { GetCustomersQuery } from "../../lib/GraphQL/generated/types";

export type SelectClientType =
  GetCustomersQuery["customers"]["customers"][number];

const CustomerPage = () => {
  const customerContext = useCustomer();
  const [clients, setClients] = useState<SelectClientType[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [filter, setFilter] = useState<string>("nombre");
  const [page, setPage] = useState<number>(1);
  const [input, setInput] = useState<string>("");
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const handleChangeInput = (text: string) => {
    setInput(text);
  };

  const history = useHistory();

  const contentRef = createRef<HTMLIonContentElement>();

  const [getCustomers, { error, refetch }] = useGetCustomersLazyQuery({
    variables: {
      input: null,
      limit: 20,
      page: 1,
    },
    onCompleted: () => {
      setFirstLoad(false);
    },
  });

  const pushData = (newData: SelectClientType[]) => {
    setClients([...clients, ...newData]);
  };

  const loadData = async (ev: any) => {
    const newResults = await refetch({
      input: { id: null },
      page: page,
    });
    if (newResults.data.customers.nextPage) {
      setPage(newResults.data.customers.nextPage);
      pushData(newResults.data.customers.customers);
      ev.target.complete();
    } else {
      setInfiniteDisabled(true);
    }
  };

  useIonViewWillEnter(async () => {
    setClients([]);
    setInput("");
    try {
      const newResults = await getCustomers({ variables: { limit: 20 } });
      if (error) {
        setErrorMessage(error.message);
        setShowAlert(true);
      } else {
        if (newResults.data) {
          if (newResults.data.customers.nextPage) {
            setPage(newResults.data.customers.nextPage);
          }
          pushData(newResults.data.customers.customers);
          if (!newResults.data.customers.nextPage) {
            setInfiniteDisabled(true);
          }
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        await setErrorMessage(e.message);
      }
      setShowAlert(true);
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilterTextHandler = useCallback(
    _.debounce(async (text: string) => {
      setPage(1);
      const newResults = await refetch({
        input:
          filter === "codigo"
            ? { id: parseInt(text) }
            : { name: text === "" ? null : text.toUpperCase() },
        page: 1,
      });
      setClients(newResults.data.customers.customers);
      if (!newResults.data.customers.nextPage) {
        setInfiniteDisabled(true);
      } else {
        setInfiniteDisabled(false);
      }
    }, 500),
    [filter]
  );

  const handleSelectClient = (client: SelectClientType) => {
    customerContext.setCustomer(client);
    history.push(`/clients/detail/${client.id}`);
  };

  const filterSelectHandler = (value: string) => {
    setFilter(value);
  };

  useEffect(() => {
    debouncedFilterTextHandler(input);
  }, [debouncedFilterTextHandler, input]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Clientes</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light" ref={contentRef}>
        <div className="search-fixed">
          <SearchBar
            placeholder="Filtrar Clientes"
            valueHandleChange={handleChangeInput}
            value={input}
            filtersEnabled
            filterHandleChange={filterSelectHandler}
            filterValue={filter}
            filtersOptions={[
              {
                label: "Codigo",
                value: "codigo",
              },
              {
                label: "Nombre",
                value: "nombre",
              },
            ]}
          />
        </div>
        {clients.length > 0 ? (
          <IonList className="list" color="transparent">
            {clients.map((client, key) => {
              return (
                <IonCard
                  key={key}
                  className="card"
                  onClick={() => handleSelectClient(client)}
                >
                  <IonGrid className="grid-fonts">
                    <IonRow
                      style={{
                        fontWeight: "600",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                      class="ion-justify-content-between"
                    >
                      <IonLabel>{client.name}</IonLabel>
                      <IonLabel>{client.id}</IonLabel>
                    </IonRow>
                    <IonRow class="ion-justify-content-between">
                      <IonLabel>{client.city}</IonLabel>
                      {client.balance > 0 ? (
                        <IonLabel>{formatToCurrency(client.balance)}</IonLabel>
                      ) : null}
                    </IonRow>
                  </IonGrid>
                </IonCard>
              );
            })}
          </IonList>
        ) : (
          <h1>No hay resultados</h1>
        )}
        <IonInfiniteScroll
          onIonInfinite={loadData}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
      <IonLoading isOpen={firstLoad} message={"Cargando..."} />
      <ErrorAlert
        isOpen={showAlert}
        dismiss={() => setShowAlert(false)}
        message={errorMessage}
      />
    </IonPage>
  );
};

export default CustomerPage;
